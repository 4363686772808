import React from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { get, startCase } from "lodash";
import { Image, Paragraph, Button, useColorTokens } from "@tvg/design-system";
import { TalentPickData } from "@tvg/ts-types/TalentPicks";
import { UnaryFn } from "@tvg/ts-types/Functional";
import buildRaceUrl, { buildTalentPicksTVG5Url } from "@tvg/formatter/url";
import { WagerTypeCodesInvertedEnum } from "@tvg/ts-types/Wager";
import formatCurrency from "@tvg/formatter/currency";
import {
  EVENTS,
  LINK_TEXT
} from "@tvg/amplitude/modules/talentPicks/constants";

import BetSummary from "./BetSummary";
import {
  CardWrapper,
  CardHeader,
  AvatarWrapper,
  SummaryInfo,
  TalentSummaryInfo,
  CardBody,
  ActionsWrapper,
  AmountContainer,
  AmountFlexWrapper,
  ButtonWrapper
} from "./styled-components";
import MtpBody from "./MtpBody";
import { isFdr } from "../../helpers";
import { triggerAmplitudeEvent, getModule } from "../../utils/amplitude";

interface Props {
  talentPick: TalentPickData;
  cardPosition: number;
  showRaceSummaryInfo?: boolean;
  showViewRaceButton?: boolean;
  showAddToBetslipButton?: boolean;
  qaLabel?: string;
  onAddToBetslip?: UnaryFn<TalentPickData, void>;
  numWagerableRunners?: string[];
  onHomePage?: boolean;
  isFDTVPage?: boolean;
}

const TalentPicksCard = ({
  talentPick,
  cardPosition,
  showAddToBetslipButton = true,
  showViewRaceButton = true,
  showRaceSummaryInfo = true,
  qaLabel = "talentPick-card",
  onAddToBetslip,
  numWagerableRunners,
  onHomePage = false,
  isFDTVPage = false
}: Props) => {
  const colorTokens = useColorTokens();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const {
    talent,
    track: trackCode,
    race: raceNumber,
    trackName,
    amount: wagerAmount,
    price: betTotal,
    wagerType,
    visualSelections,
    numRunnersAllRaces
  } = talentPick;
  const { name: talentName, pictureUrl: talentPicture } = talent;
  const {
    abbreviation: wagerTypeCode,
    isKey,
    isBox,
    legCount,
    isWheel
  } = wagerType;
  const isFDR = isFdr();
  const raceURL = buildRaceUrl(trackCode, trackName, raceNumber);
  const module = getModule(pathname);
  const amplitudePayload = {
    module,
    talentName,
    trackName,
    raceNumber,
    wagerTypeCode,
    cardPosition
  };

  const handleViewRace = () => {
    triggerAmplitudeEvent(EVENTS.CARD_CLICKED, {
      ...amplitudePayload,
      raceURL,
      linkText: LINK_TEXT.VIEW_RACE
    });
    navigate(buildRaceUrl(trackCode, trackName, raceNumber));
  };

  const handleAddToBetslip = () => {
    triggerAmplitudeEvent(EVENTS.ADD_TO_BETSLIP, {
      ...amplitudePayload,
      betTotal
    });
    triggerAmplitudeEvent(EVENTS.CARD_CLICKED, {
      ...amplitudePayload,
      raceURL,
      linkText: LINK_TEXT.ADD_TO_BETSLIP
    });

    if (onAddToBetslip) {
      onAddToBetslip(talentPick);
    } else {
      navigate(buildTalentPicksTVG5Url(talentPick));
    }
  };

  const handleOnCardHeaderClick = () => {
    navigate(raceURL);
    triggerAmplitudeEvent(EVENTS.CARD_CLICKED, {
      ...amplitudePayload,
      raceURL,
      linkText: LINK_TEXT.TALENT_NAME
    });
  };

  const wagerTypeName = startCase(
    get(WagerTypeCodesInvertedEnum, wagerTypeCode)
      .replace("_", " ")
      .toLowerCase()
  );

  return (
    <CardWrapper
      className="tpWrapper"
      data-qa-label={qaLabel}
      isOnHomePage={onHomePage}
      isFDR={isFDR}
    >
      <CardHeader
        isOnHomePage={onHomePage}
        onClick={handleOnCardHeaderClick}
        isFDTVPage={isFDTVPage}
      >
        <AvatarWrapper>
          <Image
            qaLabel="talent-avatar"
            source={talentPicture}
            width={44}
            height={44}
            resizeMode="cover"
          />
        </AvatarWrapper>
        {showRaceSummaryInfo && (
          <SummaryInfo data-qa-label="race-summary-info">
            <TalentSummaryInfo>
              <Paragraph mb={8} fontFamily="medium" qaLabel="talent-name">
                {talentName}
              </Paragraph>
              <AmountFlexWrapper>
                <AmountContainer>
                  <Paragraph
                    qaLabel="bet-amount-n-type"
                    fontFamily="regular"
                    color={colorTokens.content.subtle}
                    mr="space-1"
                  >{`${formatCurrency(
                    +wagerAmount
                  )} ${wagerTypeCode}`}</Paragraph>
                </AmountContainer>
                <AmountContainer>
                  <Paragraph
                    qaLabel="bet-total-n-type"
                    fontFamily="regular"
                    color={colorTokens.content.subtle}
                    ml="space-1"
                  >
                    {`${formatCurrency(+betTotal)} Ticket`}
                  </Paragraph>
                </AmountContainer>
              </AmountFlexWrapper>
            </TalentSummaryInfo>
          </SummaryInfo>
        )}
      </CardHeader>
      <CardBody>
        <MtpBody qaLabel="tp-body-mtp" talentPick={talentPick} />
        <BetSummary
          wagerAmount={wagerAmount}
          betTotal={betTotal}
          selections={visualSelections || []}
          wagerTypeCode={wagerTypeCode}
          wagerTypeName={wagerTypeName}
          isKey={isKey}
          isBox={isBox}
          isWheel={isWheel}
          legCount={legCount}
          numWagerableRunners={numRunnersAllRaces || numWagerableRunners}
          raceNumber={raceNumber?.toString()}
          onHandicaping
        />
      </CardBody>
      <ActionsWrapper isOnHomePage={onHomePage}>
        {showViewRaceButton && (
          <ButtonWrapper isOnHomePage={onHomePage} isViewRaceBtn>
            <Button
              qaLabel="view-race-button"
              variant="secondary"
              onPress={handleViewRace}
              isStretched
            >
              View Race
            </Button>
          </ButtonWrapper>
        )}
        {showAddToBetslipButton && (
          <ButtonWrapper isOnHomePage={onHomePage} isBettingBtn>
            <Button
              qaLabel="add-to-betslip-button"
              variant="betting"
              onPress={handleAddToBetslip}
              mb={onHomePage ? "space-4" : ""}
              isStretched
            >
              Add to Betslip
            </Button>
          </ButtonWrapper>
        )}
      </ActionsWrapper>
    </CardWrapper>
  );
};

export default TalentPicksCard;
