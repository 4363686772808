// @flow
import React from "react";
// $FlowBug
import { AppRegistry } from "react-native-web";
import { ApolloProvider } from "@apollo/client";
import ApolloContext from "@tvg/utils/apolloContext";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import GridFooterEvents from "@tvg/gtm/src/modules/GridFooter";
import { TVGThemeProviderWeb } from "@tvg/design-system";

import ApolloClient from "./apolloClient/ApolloClient";
import configureStore from "./configureStore";
import App from "./pages";

const preloadedState = window.__HOME_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
window.homepageRendered = true;

if (window.seoRendered) {
  delete window.__HOME_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
}

GridFooterEvents();

const store = configureStore(preloadedState);

const Main = () => (
  <ApolloProvider client={ApolloClient.createClient(false)}>
    <ApolloContext.Provider
      value={{
        fcpClient: ApolloClient.createClient(false, "fcp"),
        rdaClient: ApolloClient.createClient(false, "rda")
      }}
    >
      <TVGThemeProviderWeb isFanduelTheme={false}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </TVGThemeProviderWeb>
    </ApolloContext.Provider>
  </ApolloProvider>
);

const Render = () => {
  AppRegistry.registerComponent("App", () => Main);

  AppRegistry.runApplication("App", {
    initialProps: {},
    // @TODO check if we can improve the hydration
    // hydrate: true,
    rootTag: document.getElementById("home")
  });
};

export default Render();
