import styled, { css, keyframes } from "styled-components";
import { Theme } from "@tvg/design-system";
import { get } from "lodash";
import { Paragraph } from "../../../Typography";
import { BetRaceStatusType } from "../../types";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;

export const Content = styled.div`
  justify-content: center;
`;

export const PositionsContainer = styled.div<{
  raceStatus?: BetRaceStatusType;
  isLeg: boolean | undefined;
  theme: Theme;
}>`
  &:not(:last-child) {
    padding-bottom: 8px;
  }

  ${({ raceStatus, theme, isLeg }) =>
    isLeg &&
    css`
      position: relative;

      &:not(:first-child) {
        margin-top: 2px;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          width: 2px;
          height: calc(100% - 18px);
          ${raceStatus &&
          css`
            background: ${get(
              theme.colorTokens.myBets,
              `${raceStatus}.trackingColor`
            )};
          `}
          border-radius: 1px;
          position: absolute;
          top: 20px;
          left: 9px;
          z-index: 2;
        }
      }
    `}
`;

export const RowWrapper = styled.div<{
  isLeg: boolean | undefined;
  theme: Theme;
}>`
  ${({ isLeg, theme }) =>
    isLeg
      ? css`
          display: flex;
          position: relative;
        `
      : css`
          display: grid;
          grid-template-columns: 25px auto;
          column-gap: ${theme.space["space-3"]};
          row-gap: ${theme.space["space-4"]};
          position: relative;
          width: 100%;
        `}
`;

export const DetailAction = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  z-index: 3;
`;

export const PickBetLabelIconContainer = styled.div<{
  theme: Theme;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  margin-right: ${({ theme }) => theme.space["space-3"]};
  position: relative;
  z-index: 2;
`;

export const LabelIconWrapper = styled.div<{
  raceStatus?: BetRaceStatusType;
  theme: Theme;
}>`
  position: relative;
  width: 16px;
  height: 16px;
  ${({ raceStatus, theme }) => {
    if (raceStatus && ["open", "raceOff"].includes(raceStatus)) {
      return css`
        border: 2px solid
          ${get(theme.colorTokens.myBets, `${raceStatus}.border`)};
        background: ${get(
          theme.colorTokens.myBets,
          `${raceStatus}.background`
        )};
        border-radius: 50%;
      `;
    }

    return "";
  }}
`;

export const LabelDot = styled.div<{
  raceStatus?: BetRaceStatusType;
  theme: Theme;
}>`
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  top: calc(50% - 2px);
  left: calc(50% - 2px);
  border-radius: 50%;
  ${({ raceStatus, theme }) =>
    raceStatus
      ? css`
          background: ${get(
            theme.colorTokens.myBets,
            `${raceStatus}.dotColor`
          )};
        `
      : undefined}
`;

export const SaddleContainer = styled.div<{
  isLeg: boolean | undefined;
  hasDetails: boolean;
}>`
  width: ${({ isLeg, hasDetails }) => (isLeg && !hasDetails ? "64%" : "100%")};
  ${({ hasDetails, theme, isLeg }) =>
    isLeg &&
    hasDetails &&
    css`
      margin-top: ${theme.space["space-3"]};
    `}
`;

export const LegParagraph = styled(Paragraph)<{
  raceStatus?: BetRaceStatusType;
  theme: Theme;
}>`
  color: ${({ raceStatus, theme }) =>
    raceStatus
      ? get(theme.colorTokens.myBets, `${raceStatus}.textColor`)
      : theme.colors.grey["900"]};
`;

export const DetailsHr = styled.hr.attrs({
  "data-qa-label": "myBets-horizontalRule"
})<{ theme: Theme }>`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.blue["100"]};
  width: calc(100% - 4px);
  border: none;
  position: relative;
  left: 2px;
  z-index: 2;
  margin: ${({ theme }) => `${theme.space["space-3"]} 0 0`};
  animation: ${fadeIn} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export const TextAndSelectionContainer = styled.div<{
  hasDetails: boolean;
  isLeg: boolean | undefined;
}>`
  display: flex;
  flex-direction: ${({ hasDetails }) => (hasDetails ? "column" : "row")};
  ${({ isLeg }) =>
    isLeg &&
    css`
      width: 100%;
    `};
`;

export const WillPaysInfoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 2px;
  margin-top: 6px;
  color: ${({ theme }) => theme.colorTokens.content.info};
  font-family: ${({ theme }) => theme.fonts.regular};

  > div {
    margin-right: 4px;
  }
`;

export const WillPaysLabel = styled.div`
  color: ${({ theme }) => theme.colorTokens.content.neutral};
  position: absolute;
  right: 18px;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
`;
