// @flow

// $FlowFixMe
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { get } from "lodash";
import { connect, useSelector } from "react-redux";
import Poller from "@tvg/poller";
import type { Device } from "@tvg/types/Device";
import type { PreviousWinnersWRCResponse } from "@tvg/types/PreviousWinners";
import wrc from "@tvg/api/wrc";
import { isUserInAWFlows } from "@tvg/login-controller/src/selectors/user-ts";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { getAccountNumber } from "@urp/store-selectors";
import RequestMTP from "./RequestMTP";

type Props = {
  device: Device,
  isLogged: boolean,
  title: string,
  accountNumber: number,
  catchPhrase: string,
  modalTitle: string,
  isMTPNewRules: boolean
};

export function PreviousWinnersComponent(props: Props) {
  const {
    device,
    title,
    isLogged,
    accountNumber,
    catchPhrase,
    modalTitle,
    isMTPNewRules
  } = props;

  const [winners, setWinners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [matchRaces, setMatchRaces] = useState([]);
  const isAWFlowsEnabled = useSelector(isUserInAWFlows);
  const isAccountCompliant = useSelector(isAccountCompliantSelector);

  const buildMatchRaces = (previousWinners) =>
    previousWinners.map((winner) => ({
      trackCode: winner.currentRace.trackCode,
      number: winner.currentRace.raceNumber.toString()
    }));

  const fetchPreviousWinners = useCallback(
    () =>
      wrc
        .fetchPreviousWinners(accountNumber)
        .then((resp: PreviousWinnersWRCResponse) => {
          setWinners(resp.data.recommendations);
          setIsLoading(false);
          setMatchRaces(buildMatchRaces(resp.data.recommendations[2]));
        })
        .catch(() => setIsLoading(false)),
    [accountNumber]
  );

  useEffect(() => {
    const poller = new Poller();
    if (
      (isLogged && accountNumber && typeof window !== "undefined") ||
      (isAWFlowsEnabled && typeof window !== "undefined" && isAccountCompliant)
    ) {
      poller.start(fetchPreviousWinners, 30000);
    }
    return () => {
      poller.stop();
      setWinners([]);
    };
  }, [isLogged, accountNumber]);

  return useMemo(
    () =>
      isLogged && (
        <RequestMTP
          matchRaces={matchRaces}
          winners={winners}
          isLoading={isLoading}
          device={device}
          isDesktop={device === "desktop"}
          title={title}
          catchPhrase={catchPhrase}
          modalTitle={modalTitle}
          isMTPNewRules={isMTPNewRules}
        />
      ),
    [JSON.stringify(winners), isLogged, isLoading, matchRaces]
  );
}

export default connect(
  (store) => ({
    isLogged: get(store, "userData.logged", false),
    accountNumber: getAccountNumber(store),
    catchPhrase: get(
      store,
      "capi.messages.previousWinnersCatchPhrase",
      "Your previous winner is running again!"
    ),
    modalTitle: get(
      store,
      "capi.messages.previousWinnersTitle",
      "Previous Winners Running Today"
    ),
    isMTPNewRules: get(store, "capi.featureToggles.MTPColoursRules", false)
  }),
  () => ({})
)(PreviousWinnersComponent);
