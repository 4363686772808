// @flow
import { noop } from "lodash";
import TopTracks from "@tvg/atomic-ui/_templates/TopTracks";
import type { TrackComponentBehavior } from "./types";
import GraphQuery from "../graphql/queries/TopTracks.graphql";
import RDAQuery from "../graphql/queries/TopTracksRDA.graphql";
import GraphOptions from "../graphql/top-tracks-options.graph";

export const getDerivedStateFromProps = () => ({
  firstRender: false,
  innerUpdate: false
});

type QueryTypes = {
  isAccountCompliant: boolean,
  shouldUpdate: boolean
};

export const skipQuery = ({ isAccountCompliant, shouldUpdate }: QueryTypes) =>
  isAccountCompliant || !shouldUpdate;

export const skipRDAQuery = ({
  isAccountCompliant,
  shouldUpdate,
  accountId
}: QueryTypes & { accountId: string }) =>
  !isAccountCompliant || !shouldUpdate || !accountId;

const topTracks: TrackComponentBehavior = {
  template: TopTracks,
  query: GraphQuery,
  rdaQuery: RDAQuery,
  graphOptions: GraphOptions,
  skipQuery,
  skipRDAQuery,
  usesRDA: true,
  onlyUsesRDA: false,
  onlyFetchResultedTracks: false,
  renderTracksLetters: false,
  shouldComponentUpdate: noop,
  getDerivedStateFromProps
};

export default topTracks;
