import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import tvgConf from "@tvg/conf";
import {
  getDefaultPlaylistSettings,
  getHeaderTitle
} from "../../redux/selectors";
import type { Playlist } from "../../types";
import { getJWPlaylistVideos } from "../../utils";
import VideoPlaylist from "../VideoPlaylist";
import { Props } from "./types";

const isDesktop = tvgConf().device === "desktop";

const TipsAndPicks = ({ qaLabel = "" }: Props) => {
  const { playlistUrl, playerScript, licenseKey } = useSelector(
    getDefaultPlaylistSettings
  );

  const title = isDesktop ? useSelector(getHeaderTitle) : "";

  const [playlist, setPlaylist] = useState<Playlist[]>([]);

  useEffect(() => {
    getJWPlaylistVideos(playlistUrl, playerScript, licenseKey).then((data) =>
      setPlaylist(data.playlist)
    );
  }, [playlistUrl]);

  return <VideoPlaylist title={title} qaLabel={qaLabel} playlist={playlist} />;
};

export default TipsAndPicks;
