// @flow
import { attempt, get, isError } from "lodash";

export const parseJSONCapiMessage = (
  store: Object,
  capi: string,
  defaultValue: any = {}
) => {
  const messagesJSON = get(store, `${capi}`, "");
  const messages = attempt(JSON.parse, [messagesJSON]);
  return isError(messages) ? defaultValue : messages;
};

export default parseJSONCapiMessage;
