import { TalentPickData } from "@tvg/ts-types/TalentPicks";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import tvgConf from "@tvg/conf";
import { EVENTS, FILTER } from "@tvg/amplitude/modules/talentPicks/constants";
import { FilterOption, TalentFilter } from "./types";
import { triggerAmplitudeEvent } from "./utils/amplitude";

export const onFilterHandler =
  (
    options: Array<FilterOption>,
    setSelectedOption: (selected: FilterOption) => void
  ) =>
  (selectValue: string) => {
    const optionSelected = options.find(
      (option) => option.value === selectValue
    );
    if (optionSelected) setSelectedOption(optionSelected);

    triggerAmplitudeEvent(EVENTS.FILTERS_APPLIED, {
      filterName:
        options?.[0]?.label === "All Tracks" ? FILTER.TRACK : FILTER.TALENT,
      filterLabel: optionSelected ? optionSelected.label : ""
    });
  };

export const onTalentsFilterHandler =
  (
    talents: Array<TalentFilter>,
    talentsFilter: Array<TalentFilter>,
    setSelectedOption: (selected: TalentFilter[]) => void
  ) =>
  (selectValue: string): void => {
    const optionSelected = talents.find(
      (talent) => talent.value === selectValue
    );

    if (!optionSelected || optionSelected?.value === "") {
      setSelectedOption([]);
    } else {
      const existOption = talentsFilter.find(
        (talent) => talent.value === optionSelected.value
      );

      if (existOption) {
        setSelectedOption(
          talentsFilter.filter(
            (talent) => talent.value !== optionSelected.value
          )
        );
      } else {
        setSelectedOption([...talentsFilter, optionSelected]);
      }
    }
  };

export const filterTalentPicks = (
  talentPicksList: Array<TalentPickData>,
  trackCode: string,
  expertId: string
) =>
  (talentPicksList || []).filter(
    (talentPick: TalentPickData) =>
      (!trackCode || talentPick.track === trackCode) &&
      (!expertId || talentPick.talent.talentId === expertId)
  );

export const filterTalentPicksByTalents = (
  talentPicksList: Array<TalentPickData>,
  talents: Array<TalentFilter>,
  blacklistedBets: string[]
) =>
  (talentPicksList || []).filter((talentPick: TalentPickData) => {
    if (blacklistedBets?.includes(talentPick.wagerType.abbreviation)) {
      return false;
    }
    const found = talents.find(
      (talent) => talent.value === talentPick.talent.talentId
    );

    return talents.length === 0 || Boolean(found);
  });

export const getTalentPicksGroups = (
  talentPicksList: Array<TalentPickData>
): {
  talentPicksActive: Array<TalentPickData>;
  talentPicksResulted: Array<TalentPickData>;
} =>
  talentPicksList.reduce(
    (
      groups: {
        talentPicksActive: Array<TalentPickData>;
        talentPicksResulted: Array<TalentPickData>;
      },
      talentPick: TalentPickData
    ) =>
      [
        RaceStatusEnum.OPEN,
        RaceStatusEnum.UP_NEXT,
        RaceStatusEnum.RACE_OFF
      ].includes(talentPick.raceStatus)
        ? {
            talentPicksActive: [...groups.talentPicksActive, talentPick],
            talentPicksResulted: groups.talentPicksResulted
          }
        : {
            talentPicksActive: groups.talentPicksActive,
            talentPicksResulted: [...groups.talentPicksResulted, talentPick]
          },
    { talentPicksActive: [], talentPicksResulted: [] }
  );

export const filterTalentPicksByBetTypes = (
  talentPicks: Array<TalentPickData>,
  blacklistedBets: string[]
): Array<TalentPickData> =>
  talentPicks.filter(
    (talentPick: TalentPickData) =>
      !blacklistedBets?.includes(talentPick.wagerType.abbreviation)
  ) || [];

export const isFdr = () => tvgConf().brand === "fdr";
