import React, {
  PropsWithChildren,
  memo,
  useEffect,
  useRef,
  useState
} from "react";
import { Icon } from "@tvg/design-system/web";
import { Container, Content, Summary } from "./styled-components";
import { Props } from "./types";
import { buildQaLabel } from "../../utils";
import {
  COLLAPSIBLE,
  CONTAINER,
  CONTENT,
  ICON,
  SUMMARY
} from "../../utils/constants";

const Collapsible = ({
  children,
  summary,
  isOpen,
  onClick,
  qaLabel = ""
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const summaryRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);
  const [minHeight, setMinHeight] = useState(48);

  useEffect(() => {
    if (isOpen && ref?.current) {
      setHeight(ref.current.scrollHeight);
    }
  }, [isOpen]);

  useEffect(() => {
    if (summaryRef?.current) {
      setMinHeight(summaryRef.current.clientHeight);
    }
  }, []);

  return (
    <Container
      isOpen={isOpen}
      contentHeight={height}
      minHeight={minHeight}
      ref={ref}
      data-qa-label={buildQaLabel([qaLabel, COLLAPSIBLE, CONTAINER])}
    >
      <Summary
        onClick={onClick}
        ref={summaryRef}
        data-qa-label={buildQaLabel([qaLabel, COLLAPSIBLE, SUMMARY])}
      >
        {summary}
        <Icon
          qaLabel={buildQaLabel([qaLabel, COLLAPSIBLE, SUMMARY, ICON])}
          size="m"
          name={isOpen ? "chevronUp" : "chevronDown"}
          paddingX="space-3"
          paddingY="space-1"
          lineColor="content.subtle"
        />
      </Summary>
      <Content
        data-qa-label={buildQaLabel([qaLabel, COLLAPSIBLE, CONTENT])}
        isOpen={isOpen}
      >
        {children}
      </Content>
    </Container>
  );
};

export default memo(Collapsible);
