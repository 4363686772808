// @flow
import React, { PureComponent } from "react";
import type { Dispatch } from "redux";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import {
  get,
  noop,
  debounce,
  attempt,
  isNumber,
  flowRight as compose
} from "lodash";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import UpcomingRaces from "@tvg/atomic-ui/_templates/UpcomingRaces";
import type { UserOptedInPromos } from "@tvg/types/User";
import type { RacePanelLink } from "@tvg/types/Race";
import { UpcomingRacesMask } from "@tvg/atomic-ui/_static/Masks";
import filtersService from "@tvg/tracks-header/service";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import {
  type Actions,
  setRaceFiltersFromPreferences,
  setRegionFiltersFromPreferences
} from "@tvg/tracks-header/actions";
import Poller from "@tvg/poller";
import { getAccountNumber } from "@urp/store-selectors";
import type { Props } from "./types";
import UpcomingRacesQuery from "./graphql/queries/UpcomingRaces.graphql";
import UpcomingRacesRDAQuery from "./graphql/queries/UpcomingRacesRDA.graphql";
import ApolloOptions from "./graphql/options.graph";

const pollerCallback = (callback) => {
  if (typeof callback === "function") {
    callback();
  }
};
const POLLER_UPCOMING_RACES = 60000;

export class UpcomingRacesComponent extends PureComponent<Props> {
  static defaultProps = {
    wagerProfile: "PORT-Generic",
    races: [],
    shouldUpdate: true,
    isLoading: true,
    optedInPromos: {},
    signalLoadedComponent: noop,
    refetchQuery: noop,
    gotPromos: false,
    title: "Upcoming Races",
    isLogged: false,
    rdaClient: noop,
    accountId: "",
    useRDAService: false,
    raceTypeFilters: [],
    regionFilters: [],
    parentPage: "home",
    hasError: false,
    emptyMessages: {},
    pastPostTimeLimit: 30,
    isMTPNewRules: false,
    useIsPromoTagShownFlag: false
  };

  poller: Poller = new Poller();

  componentDidMount(): * {
    // Start mtp poller
    this.poller.start(
      () => pollerCallback(this.props.refetchQuery),
      POLLER_UPCOMING_RACES
    );
  }

  componentDidUpdate() {
    if (!this.props.isLoading && this.props.shouldUpdate) {
      this.props.signalLoadedComponent("upNext");
    }

    if (!this.poller.isRunning()) {
      this.poller.start(
        () => pollerCallback(this.props.refetchQuery),
        POLLER_UPCOMING_RACES
      );
    }
  }

  componentWillUnmount(): * {
    if (this.poller.isRunning()) {
      this.poller.stop();
    }
  }

  onContainerSwipeHandler = (swipeDirection: number) =>
    mediator.base.dispatch({
      type: "UPCOMING_CONTAINER_SWIPE",
      payload: {
        swipeDirection
      }
    });

  onRaceClickHandler = (
    race: RacePanelLink,
    url: string,
    optedInPromos: UserOptedInPromos
  ) => {
    const newUrl = tvgConf().buildUrl({ path: url });
    mediator.base.dispatch({
      type: "UPCOMING_RACE_CLICK",
      payload: {
        race,
        isRDA: this.props.useRDAService && this.props.isLogged,
        url: newUrl,
        optedInPromos
      }
    });
  };

  onClearFiltersHandler = () => {
    filtersService.setRaceFilterPreference([]);
    filtersService.setRegionFilterPreference([]);
    this.props.dispatch(setRaceFiltersFromPreferences([]));
    this.props.dispatch(setRegionFiltersFromPreferences([]));
  };

  render() {
    const currentTime = Date.now();
    const pastPostTimeLimit = isNumber(this.props.pastPostTimeLimit)
      ? this.props.pastPostTimeLimit
      : 30;

    const racesToDisplay = this.props.races
      .filter((race) => race.raceStatus !== "SK" && race.raceStatus !== "RO")
      .filter((race) => {
        const timeLimit = 1000 * 60 * pastPostTimeLimit;
        const postTime = Date.parse(race.postTime);
        const expirationTime = postTime + timeLimit;

        return expirationTime > currentTime;
      });

    return this.props.shouldUpdate &&
      (!this.props.isLoading || !!this.props.races.length) ? (
      // $FlowFixMe
      <UpcomingRaces
        races={racesToDisplay}
        onRaceClick={this.onRaceClickHandler}
        onContainerSwipe={debounce(this.onContainerSwipeHandler, 150)}
        isLoading={this.props.isLoading}
        hasPromoBadge={this.props.hasPromoBadge}
        optedInPromos={this.props.optedInPromos}
        title={this.props.title}
        renderAllRacesBtn={this.props.parentPage === "home"}
        shouldRenderEmptyMessages={this.props.parentPage !== "home"}
        hasError={this.props.hasError}
        onClearFilters={this.onClearFiltersHandler}
        emptyMessages={this.props.emptyMessages}
        hasActiveFilters={
          this.props.parentPage !== "home" &&
          (this.props.raceTypeFilters.length || this.props.regionFilters.length)
        }
        isMTPNewRules={this.props.isMTPNewRules}
        useIsPromoTagShownFlag={this.props.useIsPromoTagShownFlag}
      />
    ) : (
      <UpcomingRacesMask />
    );
  }
}

export const skipUpcomingRacesQuery = ({
  useRDAService,
  isAccountCompliant,
  shouldUpdate
}: {
  useRDAService: boolean,
  isAccountCompliant: boolean,
  shouldUpdate: boolean
}) => (useRDAService && isAccountCompliant) || !shouldUpdate;

export const skipUpcomingRacesRDAQuery = ({
  useRDAService,
  isAccountCompliant,
  shouldUpdate,
  accountId
}: {
  useRDAService: boolean,
  isAccountCompliant: boolean,
  shouldUpdate: boolean,
  accountId: string
}) => !shouldUpdate || !(useRDAService && isAccountCompliant && accountId);

export default connect(
  /* istanbul ignore next */
  (store) => ({
    races: get(store, "upNextRaces.races", []),
    wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
    isLogged: get(store, "userData.logged"),
    accountId: getAccountNumber(store),
    raceTypeFilters: get(store, "raceFilters.raceTypeFilters", []),
    regionFilters: get(store, "raceFilters.regionFilters", []),
    useRDAService: get(store, "capi.featureToggles.useRDAService", false),
    emptyMessages: attempt(() =>
      JSON.parse(get(store, "capi.messages.tracksEmptyMessages", {}))
    ),
    pastPostTimeLimit: get(store, "capi.messages.pastPostTimeLimit", 30),
    isMTPNewRules: get(store, "capi.featureToggles.MTPColoursRules", false),
    useIsPromoTagShownFlag: get(
      store,
      "capi.featureToggles.useIsPromoTagShownFlag",
      false
    ),
    isAccountCompliant: isAccountCompliantSelector(store)
  }),
  /* istanbul ignore next */
  (dispatch: Dispatch<Actions>) => ({ dispatch })
)(
  compose(
    graphql(UpcomingRacesQuery, {
      ...ApolloOptions,
      skip: skipUpcomingRacesQuery
    }),
    graphql(UpcomingRacesRDAQuery, {
      ...ApolloOptions,
      skip: skipUpcomingRacesRDAQuery
    }),
    connect(
      /* istanbul ignore next */
      (store) => ({
        gotPromos: get(store, "userData.gotPromos", false),
        optedInPromos: get(store, "userData.optedInPromos", []),
        isAccountCompliant: isAccountCompliantSelector(store)
      }),
      /* istanbul ignore next */
      (dispatch: Dispatch<Actions>) => ({ dispatch })
    )
  )(UpcomingRacesComponent)
);
