import React from "react";
import { useQaLabel } from "@tvg/design-system/src/hooks";
import SaddleSelections from "../saddleSelections";
import { BetSelectionContainer, BetText } from "./styled-components";

import { BetSelectionProps } from "./types";

export const BetSelections = ({
  numWagerableRunners,
  type,
  breed = "thoroughbred",
  selections,
  isDark = true,
  qaLabel = "bet-selection",
  showBetType = true,
  scratches,
  wagerDetails,
  shouldShowRefundTag,
  onHandicaping,
  hideWillPaysTag
}: BetSelectionProps) => {
  const betSelectionQaLabel = useQaLabel(qaLabel);
  return showBetType ? (
    <BetSelectionContainer {...betSelectionQaLabel} isDark={isDark}>
      <BetText isDark={isDark}>{type}</BetText>
      <SaddleSelections
        selections={selections}
        isDark={isDark}
        showBetType={showBetType}
        scratches={scratches}
        breed={breed}
        hideWillPaysTag={hideWillPaysTag}
      />
    </BetSelectionContainer>
  ) : (
    <SaddleSelections
      numWagerableRunners={numWagerableRunners}
      selections={selections}
      isDark={isDark}
      showBetType={showBetType}
      scratches={scratches}
      breed={breed}
      wagerDetails={wagerDetails}
      shouldShowRefundTag={shouldShowRefundTag}
      onHandicaping={onHandicaping}
      hideWillPaysTag={hideWillPaysTag}
      {...betSelectionQaLabel}
    />
  );
};

export default BetSelections;
