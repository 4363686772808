import { gql } from "@apollo/client";

const TrackFragment = {
  entry: gql`
    fragment Race on Race {
      id
      tvgRaceId
      raceNumber: number
      number
      track {
        id
        code
        name
      }
      mtp
      postTime
      isGreyhound
      status {
        code
      }
      type {
        code
      }
      video {
        liveStreaming
        streams
        onTvg
        onTvg2
        hasReplay
      }
      promos(product: $product, brand: $brand) {
        rootParentPromoID
        isAboveTheLine
        isPromoTagShown
      }
    }
  `
};

export default TrackFragment;
