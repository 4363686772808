import React from "react";
import { Device } from "@tvg/ts-types/Device";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import useWagerProfile from "@tvg/custom-hooks/hooks/useWagerProfile";
import { WagerProfile } from "@tvg/ts-types/User";
import { handleTouchStart } from "@tvg/utils/swipeEventHandlers";
import { useSelector } from "react-redux";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { getOptedInPromos } from "@urp/store-selectors";
import { GET_TOP_POOLS } from "../../graphql/queries";
import apolloOptions from "../../graphql/options";
import { Props } from "./types";
import TopPoolsWrapper from "../TopPoolsWrapper";
import { getPools, getSortedPools, onPoolClick, onTouchMove } from "./utils";
import { TopPoolsApolloResponse } from "../../types";
import { getCapiMessageContent } from "../../redux/selectors";
import LoadingMask from "../LoadingMask";

const TopPools = ({ device, title, ...optionsProps }: Props) => {
  const wagerProfile = useWagerProfile() as WagerProfile;

  // QUERY
  const { data, loading } = useQuery<TopPoolsApolloResponse>(
    GET_TOP_POOLS,
    apolloOptions({ ...optionsProps, wagerProfile })
  );

  // CUSTOM HOOKS
  const isDesktop = device
    ? device === Device.DESKTOP
    : useMediaQuery(breakpoints.tablet.min.lg);

  // SELECTORS
  const {
    title: capiTitle,
    highlightsTitle,
    upcomingTitle
  } = useSelector(getCapiMessageContent);
  const optedInPromos = useSelector(getOptedInPromos);

  const navigate = useNavigate();

  // VARIABLES
  const pools = getPools(data?.carryOverPools ?? []);

  if (loading) return <LoadingMask isDesktop={isDesktop} />;
  if (!pools.length) return null;

  return (
    <TopPoolsWrapper
      title={title || capiTitle}
      pools={pools.sort(getSortedPools)}
      isDesktop={isDesktop}
      onPoolClick={onPoolClick(navigate, optedInPromos)}
      onTouchStart={handleTouchStart}
      onTouchMove={onTouchMove}
      highlightsTitle={highlightsTitle}
      upcomingTitle={upcomingTitle}
    />
  );
};

export default TopPools;
