import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RaceCell, Icon } from "@tvg/design-system/web";
import buildRaceUrl from "@tvg/formatter/url";
import { Race, RaceStatusEnum, Promo } from "@tvg/ts-types/Race";
import {
  getAccountNumber,
  getIsLogged,
  getOptedInPromos as getOptedInPromosStore
} from "@urp/store-selectors/users";
import getOptedInPromos from "@tvg/utils/PromoUtils";

import {
  getActiveBets,
  getOpenTracks,
  getCurrentTabTracksPage
} from "@urp/lib-racetracks/src/redux/selectors";
import { sendRaceCellClickedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import { get } from "lodash";
import {
  isUserOptedInPromo,
  isTrackOpen
} from "@urp/lib-racetracks/src/utils/races";

import Collapsible from "../Collapsible";
import CollapsibleSummary from "../CollapsibleSummary";
import { Props } from "./types";
import { buildQaLabel, getWidthCells } from "../../utils";
import { LETTER_BLOCK, LIST, RACE } from "../../utils/constants";
import { ScrollButton, RaceListContainer } from "./styled-components";

const CollapsibleWrapper = ({
  track,
  onTrackClickHandler,
  module,
  hasScrollButtons = false,
  qaLabel = ""
}: Props) => {
  const isUserLogged = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const optedInPromos = useSelector(getOptedInPromosStore);
  const openTracks = useSelector(getOpenTracks);
  const activeBets = useSelector(getActiveBets);
  const currentTabTracksPage = useSelector(getCurrentTabTracksPage);
  const races = track.races as Race[] | undefined;
  const isOpen = isTrackOpen(openTracks, track.code) && !!races;
  const [activeIdx, setActiveIdx] = useState(0);
  const [isNextBtnEnabled, setIsNextBtnEnabled] = useState(true);
  const racesRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      const activeIdxIni = (races || []).findIndex(
        (race) =>
          race.status &&
          [RaceStatusEnum.OPEN, RaceStatusEnum.UP_NEXT].includes(
            race.status.code
          )
      );

      setActiveIdx(activeIdxIni >= 0 ? activeIdxIni : (races || []).length);
    }
  }, [isOpen]);

  useEffect(() => {
    setTimeout(() => {
      racesRef.current?.scroll({
        top: 0,
        left: getWidthCells(activeIdx, racesRef.current?.childNodes, 2),
        behavior: "smooth"
      });
    }, 250);
  }, [isOpen, activeIdx]);

  useEffect(() => {
    setIsNextBtnEnabled(
      getWidthCells(activeIdx, racesRef.current?.childNodes, 2) +
        (racesRef.current?.clientWidth || 0) <
        (racesRef.current?.scrollWidth || Infinity)
    );
  }, [isOpen, activeIdx, racesRef, racesRef.current?.scrollWidth]);

  const isPrevBtnEnabled = activeIdx > 0;

  const hasPromosAvailable = (
    promo: Promo | null,
    isOptedIn: boolean
  ): boolean => {
    if (!promo?.isAboveTheLine && !isUserLogged) {
      return false;
    }
    if ((!isUserLogged || !isOptedIn) && !!promo) {
      return true;
    }
    return false;
  };

  return (
    <Collapsible
      key={track.code}
      summary={
        <CollapsibleSummary
          trackCode={track.code}
          trackName={track.name}
          isResulted={track.isFinished}
          isUserLogged={isUserLogged}
          hasPromo={track.hasAboveTheLinePromo}
          accountNumber={accountNumber}
          qaLabel={qaLabel}
          module={module}
        />
      }
      isOpen={isOpen}
      onClick={() => onTrackClickHandler(track.code)}
      qaLabel={buildQaLabel([qaLabel, track.code])}
    >
      {hasScrollButtons && (
        <ScrollButton
          data-qa-label={buildQaLabel([qaLabel, "prev", "button"])}
          onClick={() => setActiveIdx((prev) => prev - 1)}
          disabled={!isPrevBtnEnabled}
          hasMarginLeft
        >
          <Icon name="arrowLeft" size="s" />
        </ScrollButton>
      )}

      <RaceListContainer
        ref={racesRef}
        hasScrollButtons={hasScrollButtons}
        data-qa-label={buildQaLabel([qaLabel, RACE, LIST])}
      >
        {isOpen &&
          races?.map((race: Race) => {
            const promo: Promo | null = getOptedInPromos(
              race.promos || [],
              optedInPromos,
              isUserLogged
            );
            const isOptedIn =
              isUserLogged &&
              isUserOptedInPromo(optedInPromos, race.promos || []);
            const raceUrl = buildRaceUrl(
              race.track.code,
              race.track.name,
              +race.number
            );
            return (
              <RaceCell.Summary
                key={race.id}
                race={race}
                qaLabel={buildQaLabel([qaLabel, LETTER_BLOCK, race.id])}
                to={raceUrl}
                hasPromosAvailable={hasPromosAvailable(promo, isOptedIn)}
                isOptedIn={isOptedIn}
                numberActiveBets={get(activeBets, race?.tvgRaceId ?? "", 0)}
                onClick={() =>
                  sendRaceCellClickedAnalyticEvt({
                    module,
                    trackName: race.track.name,
                    raceNumber: race.number,
                    linkUrl: raceUrl,
                    promoOptedIn: isOptedIn ? "true" : "false",
                    trackHasPromos: track.hasAboveTheLinePromo,
                    raceHasPromos: !!promo,
                    isFDTV: race.video.onTvg || race.video.onTvg2,
                    trackCountry: track.location?.country || "",
                    tabActive: currentTabTracksPage
                  })
                }
              />
            );
          })}
      </RaceListContainer>

      {hasScrollButtons && (
        <ScrollButton
          data-qa-label={buildQaLabel([qaLabel, "next", "button"])}
          onClick={() => setActiveIdx((prev) => prev + 1)}
          disabled={!isNextBtnEnabled}
          hasMarginRight
        >
          <Icon name="arrowRight" size="s" />
        </ScrollButton>
      )}
    </Collapsible>
  );
};

export default memo(CollapsibleWrapper);
