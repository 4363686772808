import { useQuery, WatchQueryFetchPolicy } from "@apollo/client";

import { WagerProfile } from "@tvg/ts-types/User";
import { GET_TODAYS_TRACKS } from "../graphql/queries/TodayTracksQuery";
import { apolloOptionsTracks, getTracksProps } from "../graphql/options.graph";

export default ({
  wagerProfile = WagerProfile.PORT_GENERIC,
  tracksFilter = [],
  regionsFilter = [],
  searchQuery = "",
  onlyFeaturedTracks = false,
  maxResults = -1,
  fetchPolicy = "cache-first",
  pollInterval = 0
}: {
  wagerProfile: WagerProfile;
  tracksFilter?: Array<string>;
  regionsFilter?: Array<string>;
  searchQuery?: string;
  onlyFeaturedTracks?: boolean;
  maxResults?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
  pollInterval?: number;
}) => {
  const { loading, data } = useQuery(
    GET_TODAYS_TRACKS,
    apolloOptionsTracks({ wagerProfile, fetchPolicy, pollInterval })
  );

  return {
    loading,
    ...getTracksProps(
      data,
      tracksFilter || [],
      regionsFilter || [],
      searchQuery,
      onlyFeaturedTracks,
      maxResults
    )
  };
};
