import mediator from "@tvg/mediator";
import {
  EVENTS,
  MODULE,
  FILTER,
  LINK_TEXT
} from "@tvg/amplitude/modules/talentPicks/constants";
import { TPTModule } from "@tvg/amplitude/modules/talentPicks/types";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { isProgramPage } from "../../components/TalentPicksCard/helpers";

interface Payload {
  module?: TPTModule;
  talentName?: string;
  trackName?: string;
  raceNumber?: number;
  wagerTypeCode?: WagerTypeCodesEnum;
  cardPosition?: number;
  raceURL?: string;
  linkText?: typeof LINK_TEXT[keyof typeof LINK_TEXT];
  betTotal?: string;
  filterName?: typeof FILTER[keyof typeof FILTER];
  filterLabel?: string;
}

export const triggerAmplitudeEvent = (event: string, payload: Payload = {}) => {
  const {
    module,
    raceURL,
    linkText,
    talentName,
    trackName,
    raceNumber,
    wagerTypeCode,
    cardPosition,
    betTotal,
    filterName,
    filterLabel
  } = payload;

  switch (event) {
    case EVENTS.CARD_CLICKED:
      mediator.base.dispatch({
        type: "TALENT_PICKS_CARD_CLICKED",
        payload: {
          module,
          raceURL,
          linkText,
          talentName,
          trackName,
          raceNumber,
          wagerTypeCode,
          cardPosition: (cardPosition as number) + 1
        }
      });
      break;
    case EVENTS.ADD_TO_BETSLIP:
      mediator.base.dispatch({
        type: "TALENT_PICKS_ADD_TO_BETSLIP",
        payload: {
          module,
          talentName,
          trackName,
          raceNumber,
          wagerTypeCode,
          cardPosition: (cardPosition as number) + 1,
          betTotal
        }
      });
      break;
    case EVENTS.CTA_CLICKED:
      mediator.base.dispatch({ type: "TALENT_PICKS_CTA_CLICKED" });
      break;
    case EVENTS.FILTERS_APPLIED:
      mediator.base.dispatch({
        type: "TALENT_PICKS_FILTERS_APPLIED",
        payload: {
          filterName,
          filterLabel
        }
      });
      break;
    case EVENTS.EMPTY_STATE_VIEWED:
      mediator.base.dispatch({
        type: "TALENT_PICKS_EMPTY_STATE_VIEWED"
      });
      break;
    default:
      return null;
  }

  return null;
};

export const getModule = (pathname: string) => {
  if (pathname === "/" || pathname === "/home") {
    return MODULE.HOME;
  }
  if (isProgramPage()) {
    return MODULE.RACE_CARD;
  }
  return MODULE.PICKS_PAGE;
};
