import { useDispatch, useSelector } from "react-redux";
import { get, throttle } from "lodash";

import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { Module } from "@tvg/amplitude/modules/raceCell/types";
import { updateFavorites } from "../utils/userFavorites";
import {
  getFavoriteTracksWithId,
  getOpenTracks,
  getCurrentTabTracksPage
} from "../redux/selectors";
import { toggleOpenTrackRow as toggleOpenTrackRowAction } from "../redux/actions/tracks";
import { isTrackOpen } from "../utils/races";

const useFavorite = (
  isLogged: boolean,
  accountNumber: string,
  module: Module
) => {
  const dispatch = useDispatch();
  const isAccountCompliant = useSelector(isAccountCompliantSelector);
  const favoriteTracksWithId = useSelector(getFavoriteTracksWithId);
  const currentTab = useSelector(getCurrentTabTracksPage);
  const openTracks = useSelector(getOpenTracks);

  const getFavoriteId = (trackCode: string) =>
    get(favoriteTracksWithId, trackCode);

  const getIsFavorite = (trackCode: string) => !!getFavoriteId(trackCode);

  const toggleOpenTracks = (code: string): string[] =>
    isTrackOpen(openTracks, code)
      ? openTracks.filter((openTrackCode) => openTrackCode !== code)
      : [...openTracks, code];

  const toggleOpenTrackRow = (code: string) => {
    const newOpenTracks = toggleOpenTracks(code);
    dispatch(toggleOpenTrackRowAction(newOpenTracks));
  };

  const toggleFavorite = throttle(
    async (trackCode: string, trackName: string) => {
      const favoriteId = getFavoriteId(trackCode);
      const isFavorite = getIsFavorite(trackCode);
      try {
        await updateFavorites(
          dispatch,
          trackCode,
          trackName,
          favoriteId,
          { isLogged, accountNumber, isAccountCompliant },
          { module, activeTab: currentTab }
        );

        // Toggle Track Row after Favorite/Unfavorite action
        if (!isFavorite || (isFavorite && isTrackOpen(openTracks, trackCode))) {
          toggleOpenTrackRow(trackCode);
        }
      } catch (e) {
        console.log(e);
      }
    },
    1000,
    {
      trailing: false
    }
  );

  return {
    isFavorite: getIsFavorite,
    toggleFavorite
  };
};

export default useFavorite;
