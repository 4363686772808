import { NullaryFn } from "@tvg/ts-types/Functional";

export enum ButtonStatus {
  DEFAULT,
  HOVER,
  ACTIVE,
  DISABLED
}

export interface NavigationButtonProps {
  /** Custom data-qa-label attribute applied to element */
  qaLabel?: string;
  pageCount: number;
  onLeftClick: NullaryFn<void>;
  onRightClick: NullaryFn<void>;
}

export interface ButtonNavProps {
  line: string;
  background: string;
}

export interface ButtonColors {
  [key: string]: ButtonNavProps;
}
