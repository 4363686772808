import type { Actions } from "../actions/tracks";

export type State = {
  openTracks: Array<string>;
};

export const initialState = {
  openTracks: []
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "TOGGLE_TRACK_ROW": {
      return {
        ...state,
        openTracks: action.payload.openTracks
      };
    }
    default: {
      return state;
    }
  }
}
