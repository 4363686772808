import React, { memo } from "react";
import { Paragraph, IconButton, Tag } from "@tvg/design-system";
import { useFavoriteWithRedux } from "@urp/lib-racetracks";
import {
  Container,
  InnerContainer,
  IconButtonContainer
} from "./styled-components";
import { Props } from "./types";
import { buildQaLabel } from "../../utils";
import {
  CONTAINER,
  FAVORITE,
  ICON,
  NON_FAVORITE,
  RESULTED,
  SUMMARY,
  TRACK
} from "../../utils/constants";

const CollapsibleSummary = ({
  trackCode,
  trackName,
  accountNumber,
  isUserLogged,
  isResulted,
  hasPromo,
  module,
  qaLabel = ""
}: Props) => {
  const { isFavorite: getIsFavorite, toggleFavorite } = useFavoriteWithRedux(
    isUserLogged,
    accountNumber,
    module
  );

  const isFavorite = getIsFavorite(trackCode);

  return (
    <Container
      data-qa-label={buildQaLabel([qaLabel, SUMMARY, CONTAINER, trackName])}
    >
      <InnerContainer>
        <IconButtonContainer
          isFavorite={isFavorite}
          data-qa-label={buildQaLabel([
            qaLabel,
            SUMMARY,
            ICON,
            CONTAINER,
            trackName
          ])}
        >
          <IconButton
            qaLabel={buildQaLabel([
              qaLabel,
              SUMMARY,
              ICON,
              trackName,
              isFavorite ? FAVORITE : NON_FAVORITE
            ])}
            onPress={() => toggleFavorite(trackCode, trackName)}
            variant="tertiary"
            size="s"
            iconName="star"
          />
        </IconButtonContainer>
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, SUMMARY, TRACK, trackName])}
          fontFamily="bold"
        >
          {trackName}
        </Paragraph>
      </InnerContainer>
      {(isResulted && (
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, SUMMARY, RESULTED, trackName])}
        >
          All resulted
        </Paragraph>
      )) ||
        (hasPromo && (
          <Tag variant="promo" label="promo" size="s" icon="promo" />
        ))}
    </Container>
  );
};

export default memo(CollapsibleSummary);
