// @flow

import React, { Component, Fragment } from "react";
import type { Dispatch } from "redux";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import ApolloContext from "@tvg/utils/apolloContext";
import tvgConf from "@tvg/conf";
import type { HandicapOptionsType } from "@tvg/types/Race";
import type { TalentPickData, ClickLocation } from "@tvg/types/TalentPicks";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import mediator from "@tvg/mediator";
import {
  isEqual,
  get,
  sample,
  without,
  noop,
  flowRight as compose
} from "lodash";
import { mapSelectionsToString } from "@tvg/utils/talentPicksUtils";
import isAppMobile from "@tvg/utils/mobileUtils";
import withRouter from "@tvg/utils/withCustomRouter";
import { isTvg5 } from "@tvg/utils/generalUtils";
// Need this until we find replacement to react router 5
// $FlowFixMe
import { type RouterHistory, Navigate } from "react-router-dom";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import {
  setPickAction,
  setApplyPickAction,
  setBetType,
  setBetAmount,
  setStartBetting,
  setSelectionRequest,
  setBetConfirmation,
  setPickEditButtonAction,
  setBetTicketState
} from "@tvg/shared-actions/BetActions";
import type { Talent } from "@tvg/types/Talent";
import { TalentPicksPageMsgs } from "@urp/talent-picks/src/types";
import TalentPicksList from "@tvg/atomic-ui/_templates/TalentPicksList";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import TalentPicksMask from "@tvg/atomic-ui/_static/Masks/talent-picks";
import buildRaceUrl, {
  buildBetSlipTalentPick,
  buildTalentPicksTVG5Url
} from "@tvg/formatter/url";
import formatCurrency from "@tvg/formatter/currency";
import { CloseButton } from "@tvg/atomic-ui/_organism/ModalHeader/styled-components";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { close } from "@tvg/atomic-ui/_static/Icons/icons";

import type { Device } from "@tvg/types/Device";
import { talentPicksPage } from "@urp/talent-picks/__mocks__/talentPicksMocks";

import { getVisualSelectionsWithRaces } from "@tvg/utils/betSelection";
import { triggerAmplitudeEvent } from "@urp/talent-picks/src/utils/amplitude";
import { EVENTS } from "@tvg/amplitude/modules/talentPicks/constants";
import { getAccountNumber } from "@urp/store-selectors";
import PicksAlerts from "./PicksAlertsModalComponent";

import ApolloOptions, { RacesApolloClient } from "./graphql/options.graph";

import TalentPicksQuery from "./graphql/queries/talentpicks.graphql";
import TalentPicksRacesQuery from "./graphql/queries/talentPicksRaces.graphql";
import type { TalentPicksRace } from "./types/talentPicksRaces";

const baseURL = `//${tvgConf().config("domain.desktop")}`;
const closedForBetting = ["RO", "SK", "C"];
const shouldRenderButtons = (talentPick: TalentPickData) =>
  closedForBetting.indexOf(talentPick.raceStatus) < 0;

const triggerSawTalentPickCommentGtm = (
  talentPick: TalentPickData,
  randomTalentPhraseId: number,
  origin: string
) => {
  mediator.base.dispatch({
    type: "TP_PROGRAMPAGE_CONFIRM_PHRASE",
    payload: {
      action: "Saw Talent Pick Comment",
      label: `Talent Pick - ${get(
        talentPick,
        "talent.name"
      )} - ${randomTalentPhraseId}`,
      talentPickOrigin: origin,
      tag: mapSelectionsToString(get(talentPick, "selections", [])),
      race: {
        raceNumber: get(talentPick, "race"),
        track: {
          trackName: get(talentPick, "trackName")
        },
        type: {
          code: get(talentPick, "raceType")
        }
      },
      betAmount: get(talentPick, "amount"),
      betType: get(talentPick, "wagerType.abbreviation")
    }
  });
};

type Props = {
  dispatch: Dispatch<*>,
  talentsList: Talent[],
  wagerProfile: string,
  shouldUpdate: boolean,
  talentPicks: TalentPickData[],
  trackCode: string,
  raceNumber: string,
  hideOnPickWager: boolean,
  isLoading: boolean,
  tvg1Link: ?string,
  tvg2Link: ?string,
  showTVG2WatchLive: boolean,
  accountId: string,
  wagerProfile: string,
  isLogged: boolean,
  onProgramPage: boolean,
  onHomePage: boolean,
  handicappingMode: HandicapOptionsType,
  enableLiveButtons: boolean,
  enableTalentFilter: boolean,
  enablePicksAlerts: boolean,
  enablePicksTracksTabAlerts: boolean,
  talentAlertsInfoMessage: string,
  trackAlertsInfoMessage: string,
  filterEmptyStateMessage: string,
  history: RouterHistory,
  talentPicksPageMsgs: TalentPicksPageMsgs,
  talentPicksOnHomepageToggle: boolean,
  talentPicksRaces: { [key: string]: Array<TalentPicksRace> }
};

type State = {
  redirect: boolean,
  redirectPath: string,
  activeFilters: Array<string>,
  isPicksModalOpen: boolean,
  talentPick: ?TalentPickData,
  isAllSelectionsOpen: boolean,
  activeIndex: number,
  talentActiveIndex: number
};

export type PickButtonType = "bet" | "edit";

export class TalentPicks extends Component<Props, State> {
  static defaultProps = {
    dispatch: noop,
    wagerProfile: "PORT-Generic",
    accountId: "",
    isLogged: false,
    talentsList: [],
    talentPicks: [],
    shouldUpdate: true,
    trackCode: "",
    raceNumber: "",
    talentAlertsInfoMessage: "",
    trackAlertsInfoMessage: "",
    tvg1Link: null,
    tvg2Link: null,
    showTVG2WatchLive: true,
    hideOnPickWager: false,
    isLoading: true,
    onProgramPage: false,
    onHomePage: false,
    handicappingMode: "basic",
    enableLiveButtons: false,
    enableTalentFilter: false,
    enablePicksAlerts: false,
    enablePicksTracksTabAlerts: false,
    filterEmptyStateMessage: "",
    history: noop,
    talentPicksPageMsgs: {},
    talentPicksOnHomepageToggle: false,
    talentPicksRaces: {}
  };

  device: Device;

  constructor(props: Props) {
    super(props);
    this.device = tvgConf().device;
    this.state = {
      redirect: false,
      redirectPath: "",
      activeFilters: [],
      isPicksModalOpen: false,
      talentPick: null,
      isAllSelectionsOpen: false,
      activeIndex: 0,
      talentActiveIndex: 0
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      this.props.shouldUpdate !== nextProps.shouldUpdate ||
      this.props.isLoading !== nextProps.isLoading ||
      this.props.tvg1Link !== nextProps.tvg1Link ||
      this.props.tvg2Link !== nextProps.tvg2Link ||
      this.props.hideOnPickWager !== nextProps.hideOnPickWager ||
      this.props.talentPicks.length !== nextProps.talentPicks.length ||
      this.state.redirect !== nextState.redirect ||
      this.state.redirectPath !== nextState.redirectPath ||
      this.state.isPicksModalOpen !== nextState.isPicksModalOpen ||
      this.state.isAllSelectionsOpen !== nextState.isAllSelectionsOpen ||
      this.props.talentPicksOnHomepageToggle !==
        nextProps.talentPicksOnHomepageToggle ||
      !isEqual(this.props.talentPicksPageMsgs, nextProps.talentPicksPageMsgs) ||
      !isEqual(this.props.isLogged, nextProps.isLogged) ||
      !isEqual(this.props.accountId, nextProps.accountId) ||
      !isEqual(this.props.wagerProfile, nextProps.wagerProfile) ||
      !isEqual(this.state.talentPick, nextState.talentPick) ||
      !isEqual(this.props.talentPicks, nextProps.talentPicks) ||
      !isEqual(this.props.talentsList, nextProps.talentsList) ||
      !isEqual(this.state.activeFilters, nextState.activeFilters) ||
      !isEqual(this.props.talentPicksRaces, nextProps.talentPicksRaces)
    );
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const newActiveFilters: string[] = prevState.activeFilters.filter(
      (el: string): boolean =>
        nextProps.talentsList.some(
          (obj: Talent): boolean => obj.talentId === el
        )
    );
    if (nextProps.talentPicks.length && prevState.talentActiveIndex !== 0) {
      const actualIndex = nextProps.talentPicks.findIndex(
        (el) => el.id === prevState.talentActiveIndex
      );
      if (actualIndex !== -1) {
        return {
          ...prevState,
          talentActiveIndex: get(
            nextProps,
            `talentPicks[${actualIndex}].id`,
            0
          ),
          activeIndex: actualIndex,
          activeFilters: newActiveFilters
        };
      }
    }
    return {
      ...prevState,
      talentActiveIndex: get(
        nextProps,
        `talentPicks[${prevState.activeIndex}].id`,
        0
      ),
      activeFilters: newActiveFilters
    };
  }

  getNavigation = (redirectPath: string) => <Navigate to={redirectPath} />;

  onClickPickCardButton = (
    talentPick: TalentPickData,
    type: PickButtonType,
    clickLocation: ClickLocation = "view race"
  ): void => {
    const pick = { ...talentPick };
    const talentPhrases = pick ? ["", ...get(pick, "talent.phrases", [])] : [];
    const randomTalentPhrase = sample(talentPhrases) || "";
    const randomTalentPhraseId = talentPhrases.indexOf(randomTalentPhrase);
    const origin = this.props.onProgramPage ? "programpage" : "pickspage";
    if (type === "bet" && (this.props.isLogged || isTvg5())) {
      if (this.device !== "desktop") {
        this.props.dispatch(setPickEditButtonAction(false));
        this.props.dispatch(setBetConfirmation(true));
        this.props.dispatch(
          setBetTicketState({
            betConfirmationTitle: "confirm bet",
            randomTalentPhraseId,
            randomTalentPhrase
          })
        );
        triggerSawTalentPickCommentGtm(pick, randomTalentPhraseId, origin);
      } else if (!isTvg5()) {
        const betSlipURL = buildBetSlipTalentPick(pick);
        window.open(betSlipURL, "", "width=535,height=778");
      }
    } else if (type === "bet" && !this.props.isLogged) {
      this.setState({ isAllSelectionsOpen: false });
      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error, success) => {
            if (get(success, "status", "fail") === "success") {
              if (this.device !== "desktop") {
                this.props.dispatch(setPickEditButtonAction(false));
                this.props.dispatch(setBetConfirmation(true));
                this.props.dispatch(setPickAction(pick, origin));
                triggerSawTalentPickCommentGtm(
                  pick,
                  randomTalentPhraseId,
                  origin
                );
                this.props.dispatch(
                  setBetTicketState({
                    betConfirmationTitle: "confirm bet",
                    randomTalentPhraseId,
                    randomTalentPhrase
                  })
                );
                this.props.dispatch(setApplyPickAction(true));
                this.runPickFlow(pick, type, clickLocation);
              } else {
                const betSlipURL = buildBetSlipTalentPick(pick);
                if (!isTvg5()) {
                  window.open(betSlipURL, "", "width=535,height=778");
                }
                this.runPickFlow(pick, type, clickLocation);
              }
            }
          },
          triggerAction: "talent_picks_click_pick_card"
        }
      });
      return;
    }

    if (type === "edit") {
      this.props.dispatch(setPickEditButtonAction(true));
      // scroll top on edit talent pick
      window.scrollTo(0, 0);
    }

    this.props.dispatch(
      setPickAction(
        pick,
        this.props.onProgramPage ? "programpage" : "pickspage"
      )
    );
    this.props.dispatch(setApplyPickAction(true));
    this.runPickFlow(pick, type, clickLocation);
  };

  onRedirectTalentPage = (url: string) => {
    const { history } = this.props;
    if (isTvg5()) {
      history.push(url);
    }

    if (this.device === "desktop" && !isTvg5()) {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: url }
      });
    }
  };

  onHandleTransition = (activeIndex: number = this.state.activeIndex) => {
    if (activeIndex > this.state.activeIndex) {
      mediator.base.dispatch({
        type: "TALENT_PICKS_SEE_NEXT_RACES"
      });
    } else if (activeIndex < this.state.activeIndex) {
      mediator.base.dispatch({
        type: "TALENT_PICKS_SEE_PREVIOUS_RACES"
      });
    }

    this.setState({
      activeIndex,
      talentActiveIndex: get(this.props, `talentPicks[${activeIndex}].id`)
    });
  };

  onClickHeader = (
    talentPick: TalentPickData,
    url: string,
    clickLocation: ClickLocation,
    operationType: PickButtonType = "edit"
  ) => {
    const newClickLocation =
      operationType !== "edit" ? "create bet ticket" : clickLocation;
    this.homepageViewRaceGTM(talentPick, url, newClickLocation);

    if (this.device === "desktop") {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: url }
      });
    }
  };

  onFilterClick = (filterClicked: string, talentName: ?string) => {
    if (filterClicked !== "open_alerts_modal") {
      let activeFilters;
      let gtmEvent;
      if (!this.state.activeFilters.some((el) => el === filterClicked)) {
        activeFilters =
          this.props.talentsList.length - 1 !== this.state.activeFilters.length
            ? this.state.activeFilters.concat(filterClicked)
            : [];

        gtmEvent = "Toggle On";
      } else {
        activeFilters = without(this.state.activeFilters, filterClicked);
        gtmEvent = "Toggle Off";
      }

      mediator.base.dispatch({
        type: "TP_TALENT_CLICK",
        payload: {
          talentFilterState: gtmEvent,
          talentName
        }
      });
      this.setState({ activeFilters });
    } else if (filterClicked === "open_alerts_modal" && this.props.isLogged) {
      this.setState({ isPicksModalOpen: true });
      mediator.base.dispatch({ type: "TP_ALERTS_CLICK" });
    } else {
      mediator.base.dispatch({
        type: "OPEN_LOGIN",
        payload: {
          callback: (error, success) => {
            if (get(success, "status", "fail") === "success") {
              this.setState({ isPicksModalOpen: true });
              mediator.base.dispatch({ type: "TP_ALERTS_CLICK" });
            }
          },
          triggerAction: "talent_picks_filter_click"
        }
      });
    }
  };

  onSeeAllSelections = (pick: TalentPickData) => {
    this.setState({
      talentPick: pick,
      isAllSelectionsOpen: true
    });
  };

  onCloseModal = () => {
    this.setState({
      isAllSelectionsOpen: false
    });
  };

  filterPicks = (): TalentPickData[] => {
    let { talentPicks } = this.props;
    if (
      this.state.activeFilters.length !== 0 &&
      this.state.activeFilters.length < this.props.talentsList.length
    ) {
      talentPicks = this.props.talentPicks.filter((pick) =>
        this.state.activeFilters.includes(pick.talent.talentId)
      );
    }

    return talentPicks.map((talentPick) => {
      const currentTrack = [
        ...(get(this.props.talentPicksRaces, talentPick.track) || [])
      ];
      const currentRaceIndex = currentTrack.findIndex(
        (race) => race.id === talentPick.raceId
      );
      const neededRaces =
        currentRaceIndex !== -1
          ? currentTrack.splice(
              currentRaceIndex,
              talentPick.selections?.length || 0
            )
          : [];

      const isPick = talentPick.wagerType.legCount > 1;

      return {
        ...talentPick,
        visualSelections: getVisualSelectionsWithRaces(
          talentPick.selections,
          neededRaces,
          isPick
        ),
        numRunnersAllRaces: neededRaces.map((race) => race.numWagerableRunners)
      };
    });
  };

  triggerGTM = (talentPick: TalentPickData, operationType: PickButtonType) => {
    const basePayload = {
      tag: mapSelectionsToString(talentPick.selections),
      race: {
        raceNumber: `${talentPick.race}`,
        track: { trackName: talentPick.trackName },
        type: { code: talentPick.raceType }
      },
      betAmount: parseFloat(talentPick.amount),
      betType: talentPick.wagerType.abbreviation
    };

    mediator.base.dispatch(
      operationType === "bet"
        ? {
            type: this.props.onProgramPage
              ? "TP_PROGRAMPAGE_BET"
              : "TP_TPPAGE_BET",
            payload: {
              action: "Bet Submit",
              label: `Talent Pick - ${talentPick.talent.name}`,
              ...basePayload,
              module: this.props.onHomePage ? "homepage" : "pickspage"
            }
          }
        : {
            type: this.props.onProgramPage
              ? "TP_PROGRAMPAGE_EDIT_BET"
              : "TP_TPPAGE_EDIT_BET",
            payload: {
              action: "Add To Betslip",
              label: `Edit Talent Pick - ${talentPick.talent.name}`,
              ...basePayload,
              tag: this.props.handicappingMode,
              module: this.props.onHomePage ? "homepage" : "pickspage"
            }
          }
    );
  };

  homepageViewRaceGTM = (
    talentPick: TalentPickData,
    url: string,
    clickLocation: ClickLocation
  ) => {
    const newUrl = tvgConf().buildUrl({ path: url });

    mediator.base.dispatch({
      type: "HP_TP_VIEW_RACE",
      payload: { talentPick, url: newUrl, clickLocation }
    });
  };

  runPickFlow = (
    talentPick: TalentPickData,
    operationType: PickButtonType,
    clickLocation: ClickLocation
  ) => {
    // Do not redirect if we are on program page

    if (this.props.onProgramPage) {
      const wagerTypeId = get(talentPick, "wagerType.id", 0);
      const wagerTypeCode = get(talentPick, "wagerType.abbreviation", "");
      const selections = get(talentPick, "selections", [[]]);
      const betAmount = get(talentPick, "amount", "");

      if (
        betAmount &&
        get(selections, "[0].length", 0) &&
        wagerTypeId &&
        wagerTypeCode
      ) {
        if (selections) {
          this.props.dispatch(setSelectionRequest(selections));
        }
        if (wagerTypeId && wagerTypeCode) {
          this.props.dispatch(
            // $FlowFixMe
            setBetType({
              type: {
                id: +wagerTypeId,
                code: wagerTypeCode,
                name: ""
              }
            })
          );
        }
        if (betAmount) {
          this.props.dispatch(setBetAmount(+betAmount));
        }
        this.props.dispatch(setStartBetting(true));
        this.triggerGTM(talentPick, operationType);

        return;
      }
    }

    const trackAbbr = get(talentPick, "track", "");
    const trackName = get(talentPick, "trackName", "");
    const raceNumber = get(talentPick, "race", "");
    const redirectPath =
      this.props.onHomePage &&
      isTvg5() &&
      operationType === "bet" &&
      clickLocation === "view race"
        ? buildTalentPicksTVG5Url(talentPick)
        : buildRaceUrl(trackAbbr, trackName, raceNumber);

    if (this.props.onHomePage && this.device === "desktop") {
      this.onClickHeader(
        talentPick,
        redirectPath,
        clickLocation,
        operationType
      );
    } else if (
      this.props.onHomePage &&
      this.device !== "desktop" &&
      clickLocation === "header"
    ) {
      this.homepageViewRaceGTM(talentPick, redirectPath, "header");
    }

    this.setState({
      redirect: true,
      redirectPath
    });

    this.triggerGTM(talentPick, operationType);
  };

  createButtonLabel =
    (type: PickButtonType) =>
    (price: number): string => {
      if (this.device !== "desktop") {
        return type === "bet" ? `BET NOW ${formatCurrency(price)}` : "EDIT BET";
      }
      return type === "bet" ? `CREATE TICKET` : "VIEW RACE";
    };

  createButton = (type: PickButtonType) => ({
    type: type === "bet" ? "bet" : "secondary",
    label: this.createButtonLabel(type),
    qaLabel: `pick_${type}_button`,
    onClick: (talentPick: TalentPickData) => {
      this.onClickPickCardButton(talentPick, type);
    }
  });

  closePicksAlerts = (userClose: boolean = false) => {
    if (userClose) {
      mediator.base.dispatch({
        type: "PICKS_ALERTS:USER_ACTIONS",
        payload: {
          action: "Close"
        }
      });
    }
    this.setState({ isPicksModalOpen: false });
  };

  triggerCTAClickedAmplitudeEvent = () => {
    triggerAmplitudeEvent(EVENTS.CTA_CLICKED);
  };

  renderCloseButton = () => (
    <CloseButton
      onClick={this.onCloseModal}
      qaLabel="modal-closeBtn"
      translateHeader={this.device === "desktop"}
    >
      <span>Close</span>
      <Icon icon={close} color={buildColor("white", "100")} size={16} />
    </CloseButton>
  );

  renderAllSelectionsModal = () => {
    if (this.state.talentPick) {
      const isMobile = this.device === "mobile";
      const pickCardButtons = [
        this.createButton("edit"),
        this.createButton("bet")
      ];
      const modalProps = {
        device: this.device,
        isOpen: this.state.isAllSelectionsOpen,
        animation: isMobile ? "bottom" : "fade",
        isFullWidth: isMobile,
        isFullHeight: false,
        isContentTransparent: true,
        hasHeader: false,
        modalTopChildren: this.renderCloseButton
      };
      return (
        <ModalV2 {...modalProps} talentPickDesktop={this.device === "desktop"}>
          {
            /* istanbul ignore next */
            () => (
              <TalentPicksList
                device={this.device}
                // $FlowFixMe
                talentPickList={[this.state.talentPick]}
                onModalDesktop={this.device === "desktop"}
                imageBaseURL={baseURL}
                showButtonsCallBack={shouldRenderButtons}
                buttons={pickCardButtons}
                onClickHeader={
                  this.device === "desktop"
                    ? // $FlowFixMe
                      this.onClickHeader
                    : // $FlowFixMe
                      this.onClickPickCardButton
                }
              />
            )
          }
        </ModalV2>
      );
    }

    return null;
  };

  renderList = () => {
    if (
      this.props.onHomePage &&
      !this.props.isLoading &&
      !this.props.talentPicks.length
    ) {
      return null;
    }

    const pickCardButtons = [
      this.createButton("edit"),
      this.createButton("bet")
    ];

    return (!this.props.isLoading || this.props.onHomePage) &&
      this.props.shouldUpdate ? (
      <TalentPicksList
        device={this.device}
        activeIndex={this.state.activeIndex}
        hasAlerts={isAppMobile(tvgConf().product)}
        talentsFilter={this.props.talentsList}
        activeFilters={this.state.activeFilters}
        onFilterClick={this.onFilterClick}
        imageBaseURL={baseURL}
        talentPickList={this.filterPicks()}
        isRaceList={this.props.trackCode !== "" && this.props.raceNumber !== ""}
        tvg1Link={this.props.tvg1Link}
        tvg2Link={
          this.props.showTVG2WatchLive ? this.props.tvg2Link : undefined
        }
        hideOnPickWager={this.props.hideOnPickWager}
        showButtonsCallBack={shouldRenderButtons}
        buttons={pickCardButtons}
        enableLiveButtons={this.props.enableLiveButtons}
        onProgramPage={this.props.onProgramPage}
        onHomePage={this.props.onHomePage}
        enableTalentFilter={this.props.enableTalentFilter}
        picksAlertsToggle={this.props.enablePicksAlerts}
        filterEmptyStateMessage={this.props.filterEmptyStateMessage}
        isLoading={this.props.isLoading}
        onSeeAllSelections={
          this.props.onHomePage ? this.onSeeAllSelections : null
        }
        onRedirectTalentPage={this.onRedirectTalentPage}
        onClickHeader={
          this.device === "desktop"
            ? // $FlowFixMe
              this.onClickHeader
            : // $FlowFixMe
              this.onClickPickCardButton
        }
        onHandleTransition={this.onHandleTransition}
        talentPicksOnHomepageToggle={this.props.talentPicksOnHomepageToggle}
        talentPicksPageMsgs={this.props.talentPicksPageMsgs}
        triggerCTAClickedAmplitudeEvent={this.triggerCTAClickedAmplitudeEvent}
      />
    ) : (
      <TalentPicksMask />
    );
  };

  render() {
    const { redirect, redirectPath, isPicksModalOpen } = this.state;

    return (
      <Fragment>
        {redirect
          ? this.getNavigation(redirectPath)
          : (!!this.props.talentPicks.length || !this.props.onHomePage) &&
            this.renderList()}
        {this.props.onHomePage && this.renderAllSelectionsModal()}
        {this.props.enablePicksAlerts && (
          <ApolloContext.Consumer>
            {(value) => (
              <PicksAlerts
                isOpen={isPicksModalOpen && get(this.props, "accountId")}
                title="tvg picks alert"
                onClose={this.closePicksAlerts}
                talentAlertsInfoMessage={this.props.talentAlertsInfoMessage}
                trackAlertsInfoMessage={this.props.trackAlertsInfoMessage}
                wagerProfile={get(this.props, "wagerProfile")}
                accountNumber={get(this.props, "accountId")}
                enablePicksTracksTabAlerts={
                  this.props.enablePicksTracksTabAlerts
                }
                fcpClient={value.fcpClient}
              />
            )}
          </ApolloContext.Consumer>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  connect(
    (
      store,
      props:
        | {
            dispatch?: Dispatch<*>,
            wagerProfile: string,
            shouldUpdate: boolean,
            talentPicks: TalentPickData[],
            trackCode: string,
            raceNumber: string,
            onProgramPage: boolean
          }
        | {
            shouldUpdate: boolean,
            talentPicks?: TalentPickData[],
            onProgramPage?: boolean
          }
    ) => ({
      dispatch: get(props, "dispatch", noop),
      shouldUpdate: props.shouldUpdate,
      talentPicks: props.talentPicks,
      trackCode: get(props, "trackCode", ""),
      raceNumber: get(props, "raceNumber", ""),
      onProgramPage: get(props, "onProgramPage", false),
      wagerProfile: get(store, "userData.user.profile", "PORT-Generic"),
      isLogged: get(store, "userData.logged"),
      accountId: getAccountNumber(store),
      filterEmptyStateMessage: get(
        store,
        "capi.messages.TalentPicksFilterEmptyState"
      ),
      enableTalentFilter: get(
        store,
        "capi.featureToggles.talentsFilterToggle",
        false
      ),
      handicappingMode: get(store, "RaceProgram.handicappingMode"),
      enableLiveButtons: get(store, "capi.featureToggles.TPwithTVGLiveButtons"),
      enablePicksAlerts: get(store, "capi.featureToggles.picksAlertsUFC"),
      enablePicksTracksTabAlerts: get(
        store,
        "capi.featureToggles.picksAlertsTracksTab"
      ),
      talentAlertsInfoMessage: get(
        store,
        "capi.messages.PickTrackAlertsInfoMessage"
      ),
      trackAlertsInfoMessage: get(
        store,
        "capi.messages.trackPickAlertsInfoMessage",
        "Get notified when TVG picks are submitted on your favorite tracks"
      ),
      talentPicksPageMsgs: parseCAPIMessage(
        store,
        "capi.messages.TalentPicksPage",
        talentPicksPage
      ),
      talentPicksOnHomepageToggle: get(
        store,
        "capi.featureToggles.talentPicksOnHomepage",
        false
      ),
      showTVG2WatchLive: get(
        store,
        "capi.featureToggles.showTVG2WatchLive",
        false
      )
    }),
    (dispatch: Dispatch<*>) => ({ dispatch })
  )(
    compose(
      graphql(TalentPicksQuery, ApolloOptions),
      graphql(TalentPicksRacesQuery, RacesApolloClient)
    )(TalentPicks)
  )
);
