type ToggleOpenTrackRow = {
  type: "TOGGLE_TRACK_ROW";
  payload: {
    openTracks: Array<string>;
  };
};

export type Actions = ToggleOpenTrackRow;

export const toggleOpenTrackRow = (
  openTracks: Array<string>
): ToggleOpenTrackRow => ({
  type: "TOGGLE_TRACK_ROW",
  payload: { openTracks }
});
