import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import Icon from "@fdr/static-ui/Icons";
import { arrowRight } from "@fdr/static-ui/Icons/icons";
import { Paragraph, useTheme } from "@tvg/design-system";
import { EVENTS } from "@tvg/amplitude/modules/talentPicks/constants";
import TalentPicksCard from "../TalentPicksCard";
import CardsLoading from "../CardsLoading";
import {
  TalentPicksContainer,
  ContentContainer,
  LinkContainer,
  IconContainer,
  AllTalentPicksWrapper,
  CardsPgpContainer,
  DesktopTitleContainer,
  HeaderLink
} from "./styled-components";
import TalentPicksPgP from "./TalentPicksPgP";
import useTalentPicksWithFilters from "../../hooks/useTalentPicksWithFilters";
import { triggerAmplitudeEvent } from "../../utils/amplitude";
import { getTalentPicksPageMsgs } from "../../selectors";

interface TalentPickListProps {
  qaLabel?: string;
  size?: number;
  moduleRef?: React.MutableRefObject<HTMLSpanElement>;
}

const handleSeeAll = () => {
  triggerAmplitudeEvent(EVENTS.CTA_CLICKED);
};

const TalentPickList: FC<TalentPickListProps> = ({
  qaLabel = "talentPicks",
  size = 2,
  moduleRef
}) => {
  const theme = useTheme();
  const { loading, talentPicksActive } = useTalentPicksWithFilters();
  const hasPicksActive = talentPicksActive.length > 0;
  const talentPicksPageMsgs = useSelector(getTalentPicksPageMsgs);

  useEffect(() => {
    const moduleOnHome = moduleRef?.current;
    if (moduleOnHome) {
      moduleOnHome.hidden = !hasPicksActive;
    }
  }, [talentPicksActive]);

  if (!hasPicksActive) {
    return null;
  }

  return (
    <TalentPicksContainer theme={theme} data-qa-label={qaLabel}>
      <ContentContainer theme={theme}>
        <DesktopTitleContainer>
          <Paragraph
            qaLabel={`${qaLabel}-title`}
            fontSize={18}
            fontFamily="bold"
            marginRight={12}
          >
            {talentPicksPageMsgs?.title}
          </Paragraph>
          <HeaderLink theme={theme} to="/handicapping/talent-picks">
            <Paragraph
              qaLabel="seeAllPicksLabel"
              fontFamily="bold"
              fontSize={13}
            >
              See all
            </Paragraph>
          </HeaderLink>
        </DesktopTitleContainer>
        {loading ? (
          <CardsLoading />
        ) : (
          <>
            {hasPicksActive ? (
              <CardsPgpContainer
                theme={theme}
                hasMultipleCards={false}
                data-qa-label={`${qaLabel}-cards-active`}
              >
                {talentPicksActive
                  .filter((_, index) => !size || index < size)
                  .map((talentPick, i) => (
                    <TalentPicksCard
                      key={talentPick.id}
                      cardPosition={i}
                      talentPick={talentPick}
                      showAddToBetslipButton={
                        talentPick.raceStatus !== RaceStatusEnum.RACE_OFF
                      }
                      isFDTVPage
                    />
                  ))}
                <LinkContainer
                  theme={theme}
                  data-qa-label="seAllPicksLinkContainer"
                >
                  <Link
                    to="/handicapping/talent-picks"
                    data-qa-label="seAllPicksLinkContainerLink"
                    onClick={handleSeeAll}
                  >
                    <AllTalentPicksWrapper>
                      <IconContainer
                        theme={theme}
                        data-qa-label="seAllPicksLinkContainerIconContainer"
                      >
                        <Icon
                          icon={arrowRight}
                          color={theme.colors.blue_accent["600"]}
                          size={24}
                        />
                      </IconContainer>
                      <Paragraph
                        qaLabel="seeAllPicksLabel"
                        fontFamily="bold"
                        fontSize={13}
                        color={theme.colors.blue_accent[600]}
                      >
                        See all Picks
                      </Paragraph>
                    </AllTalentPicksWrapper>
                  </Link>
                </LinkContainer>
              </CardsPgpContainer>
            ) : null}
          </>
        )}
      </ContentContainer>
    </TalentPicksContainer>
  );
};

export { TalentPicksPgP };
export default TalentPickList;
