import { RaceTracksFilterActionTypes } from "../actions/raceTracksFilter";

export interface RaceTracksFilterState {
  raceTypesFilter: string[];
  regionsFilter: string[];
}

export const initialState: RaceTracksFilterState = {
  raceTypesFilter: [],
  regionsFilter: []
};

const raceTracksFilterReducer = (
  state = initialState,
  action: RaceTracksFilterActionTypes
) => {
  switch (action.type) {
    case "SET_RACE_TYPE_FILTERS":
      return { ...state, raceTypes: action.payload };

    case "SET_REGION_FILTERS":
      return { ...state, regions: action.payload };

    case "CLEAR_FILTERS":
      return initialState;

    default:
      return state;
  }
};

export default raceTracksFilterReducer;
