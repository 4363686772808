import tvgConf from "@tvg/conf";
import { QueryHookOptions } from "@apollo/client";
import { get } from "lodash";
import { ApolloOptionsProps } from "../types";
import { POLL_INTERVAL } from "../constants";

const conf = tvgConf();

export default (props: ApolloOptionsProps): QueryHookOptions => {
  const wagerProfile = get(props, "wagerProfile");

  const variables = {
    wagerProfile,
    ...conf.graphContext()
  };

  return {
    skip: !get(props, "shouldUpdate", false) || !wagerProfile,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "cache-and-network",
    ssr: false,
    variables
  };
};
