import React, { FC, useEffect, useRef, useState } from "react";
import NavigationButton from "../NavigationButton";
import { CarouselContainer, Header, ListItems } from "./styled-components";
import { CarouselProps } from "./types";
import { Paragraph } from "../../../src/components/typography";

export const Carousel: FC<CarouselProps> = ({
  items,
  pageSize = 3,
  qaLabel,
  onScroll,
  title = ""
}) => {
  const container = useRef<HTMLDivElement>(null);
  const [itemWidth, setItemWidth] = useState(0);
  const [maxPage, setMaxPage] = useState(0);

  const [scrollOptions, setScrollOptions] = useState({
    left: 0
  });

  useEffect(() => {
    const element = container.current;
    element?.scroll({ ...scrollOptions, behavior: "smooth" });
  }, [scrollOptions]);

  useEffect(() => {
    const element = container.current;
    if (items.length > 1 && element?.childNodes[0]) {
      setItemWidth((element.children[0] as HTMLDivElement).scrollWidth);
      setMaxPage(Math.ceil(items.length / pageSize));
    }
  }, [items]);

  useEffect(() => {
    const scrollContainer = container.current;

    if (onScroll && typeof onScroll === "function" && scrollContainer) {
      scrollContainer.addEventListener("scroll", () =>
        onScroll(scrollContainer)
      );
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener(
          "scroll",
          () => onScroll && onScroll(scrollContainer)
        );
      }
    };
  }, []);

  const handleLeftNav = () => {
    setScrollOptions({
      ...scrollOptions,
      left: scrollOptions.left - itemWidth * pageSize
    });
  };

  const handleRightNav = () => {
    setScrollOptions({
      ...scrollOptions,
      left: scrollOptions.left + itemWidth * pageSize
    });
  };

  return (
    <CarouselContainer data-qa-label={qaLabel}>
      <Header>
        <Paragraph qaLabel="carousel-title" fontSize={18} fontFamily="bold">
          {title}
        </Paragraph>
        <NavigationButton
          qaLabel="carousel-navigation"
          pageCount={maxPage}
          onLeftClick={handleLeftNav}
          onRightClick={handleRightNav}
        />
      </Header>
      <ListItems ref={container}>{items}</ListItems>
    </CarouselContainer>
  );
};
