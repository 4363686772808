// @flow
import React, { PureComponent } from "react";
import { noop, get, forEach, orderBy } from "lodash";
import { graphql } from "@apollo/client/react/hoc";
import mediator from "@tvg/mediator";

import type { UnaryFn } from "@tvg/types/Functional";
import type { ChannelUFC } from "@tvg/types/Alerts";
import tvgConf from "@tvg/conf";
import type { TvgConf } from "@tvg/conf/src/types";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import tpmService from "@tvg/api/tpm";
import ufcService from "@tvg/api/ufc";
import PickAlertsTemplate, {
  type SelectedTab,
  type TalentAlert,
  type TrackAlert
} from "@tvg/atomic-ui/_templates/PicksAlerts";

import ApolloOptions from "./graphql/options.graph";
import AllTracksQuery from "./graphql/queries/allTracks.graphql";

type Track = {
  id: string,
  name: string
};

type Props = {
  isOpen: boolean,
  enablePicksTracksTabAlerts: boolean,
  title: string,
  onClose: UnaryFn<?boolean, void>,
  wagerProfile: string,
  talentAlertsInfoMessage: string,
  trackAlertsInfoMessage: string,
  tracks: Track[],
  isLoading: boolean,
  accountNumber: string,
  fcpClient: mixed
};

type State = {
  talentAlerts: TalentAlert[],
  trackAlerts: TrackAlert[],
  selectedTab: SelectedTab,
  isLoading: boolean
};

type AlertsToUpdate = {
  add: string[],
  remove: number[]
};

export const findTrackPickSubscription = (ufcTrackAlert: ChannelUFC): boolean =>
  !!ufcTrackAlert.subscriptions.find(
    (subscription) => subscription.eventType === "pickCreated"
  );

export const getAlertsToUpdateUFC = (
  // $FlowBug
  stateAlerts: TalentAlert[] | TrackAlert[],
  ufcAlerts: ChannelUFC[]
): AlertsToUpdate => {
  const resAlerts = { add: [], remove: [] };

  stateAlerts.forEach((stateAlert) => {
    if (stateAlert.isActive && stateAlert.isNew) {
      resAlerts.add.push(stateAlert.id);
    } else if (!stateAlert.isActive) {
      const alertToRemove = ufcAlerts.find(
        (ufcAlert) => ufcAlert.entityName === stateAlert.id
      );
      if (alertToRemove) {
        resAlerts.remove.push(alertToRemove.favoriteId);
      }
    }
  });
  return resAlerts;
};

export const updateAlertsOnUFC = (
  accountNumber: string,
  alertsToUpdate: AlertsToUpdate,
  entityType: SelectedTab
): void => {
  forEach(alertsToUpdate, (value, key) => {
    if (key === "add") {
      if (value.length) {
        ufcService.setUserAlerts(accountNumber, value, entityType, [
          "pickCreated"
        ]);
      }
    }

    if (key === "remove") {
      if (value.length) {
        ufcService.deleteUserAlert(accountNumber, value);
      }
    }
  });
};

export const updateAlertsOnState = (
  // $FlowBug
  stateAlerts: TalentAlert[] | TrackAlert[],
  id: string
): TalentAlert[] | TrackAlert[] =>
  stateAlerts.map((stateAlert) =>
    id === stateAlert.id
      ? {
          ...stateAlert,
          isActive: !stateAlert.isActive,
          isNew: true
        }
      : stateAlert
  );

export const fetchAlerts = (accountNumber: string, entityTypes: string) =>
  ufcService
    .getUserAlerts(accountNumber, entityTypes)
    .then((result) => get(result, "data.favorites"));

export const getUpdatedAlerts = (
  // $FlowBug
  defaultAlerts: TalentAlert[] | TrackAlert[],
  userAlerts: ChannelUFC[]
  // $FlowBug
): TalentAlert[] | TrackAlert[] =>
  defaultAlerts.map((alert: TalentAlert | TrackAlert) =>
    userAlerts.find(
      (userAlert: ChannelUFC) => userAlert.entityName === alert.id
    )
      ? { ...alert, isActive: true }
      : alert
  );

export class PicksAlertsModalComponent extends PureComponent<Props, State> {
  static defaultProps = {
    isOpen: false,
    isLoading: false,
    enablePicksTracksTabAlerts: false,
    onClose: noop,
    fcpClient: noop,
    title: "",
    accountNumber: "",
    talentAlertsInfoMessage: "",
    trackAlertsInfoMessage: "",
    wagerProfile: "PORT-Generic",
    tracks: []
  };

  tvg: TvgConf;

  userTrackAlerts: ChannelUFC[];

  userTalentAlerts: ChannelUFC[];

  constructor(props: Props): void {
    super(props);
    this.tvg = tvgConf();
    this.state = {
      talentAlerts: [],
      trackAlerts: [],
      selectedTab: "talent",
      isLoading: false
    };
    this.userTrackAlerts = [];
    this.userTalentAlerts = [];
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.isOpen && !this.props.isOpen && nextProps.accountNumber) {
      this.setState({ isLoading: true });
      this.resetState();

      this.fetchTalents().then(() => {
        const entityTypes = `talent${
          nextProps.enablePicksTracksTabAlerts ? ", track" : ""
        }`;
        fetchAlerts(nextProps.accountNumber, entityTypes).then((favorites) => {
          favorites.forEach((favorite) => {
            if (
              favorite.entityType === "track" &&
              findTrackPickSubscription(favorite)
            ) {
              this.userTrackAlerts.push(favorite);
            }

            if (favorite.entityType === "talent") {
              this.userTalentAlerts.push(favorite);
            }
          });

          // find active alerts
          const updatedTrackAlerts = getUpdatedAlerts(
            this.state.trackAlerts,
            this.userTrackAlerts
          );

          const updatedTalentAlerts = getUpdatedAlerts(
            this.state.talentAlerts,
            this.userTalentAlerts
          );

          this.setState({
            // $FlowBug
            trackAlerts: orderBy(updatedTrackAlerts, ["isActive"], ["desc"]),
            // $FlowBug
            talentAlerts: orderBy(updatedTalentAlerts, ["isActive"], ["desc"]),
            isLoading: false
          });
        });
      });
    }
    if (nextProps.tracks.length && !this.props.tracks.length) {
      const trackAlerts = nextProps.tracks.map((track) => ({
        trackName: track.name,
        id: track.id,
        isActive: false,
        isNew: false,
        onClick: (isActive: boolean) =>
          this.updateAlerts("track", track.id, track.name, isActive)
      }));

      this.setState({
        trackAlerts
      });
    }
  }

  resetState = () => {
    this.userTrackAlerts = [];
    this.userTalentAlerts = [];

    this.setState({
      talentAlerts: [],
      trackAlerts: [],
      selectedTab: "talent"
    });
  };

  updateAlerts = (
    type: SelectedTab,
    id: string,
    name: string,
    isActive: boolean
  ) => {
    if (type === "track") {
      const updatedTracksAlerts = updateAlertsOnState(
        this.state.trackAlerts,
        id
      );

      this.setState({
        // $FlowBug
        trackAlerts: updatedTracksAlerts
      });

      mediator.base.dispatch({
        type: "PICKS_ALERTS:TOGGLE_CLICK",
        payload: {
          toggleStatus: `${isActive ? "Off" : "On"}`,
          entityType: "Track",
          name
        }
      });
    }
    if (type === "talent") {
      const updatedTalentAlerts = updateAlertsOnState(
        this.state.talentAlerts,
        id
      );

      this.setState({
        // $FlowBug
        talentAlerts: updatedTalentAlerts
      });

      mediator.base.dispatch({
        type: "PICKS_ALERTS:TOGGLE_CLICK",
        payload: {
          toggleStatus: `${isActive ? "Off" : "On"}`,
          entityType: "Talent",
          name
        }
      });
    }
  };

  cancelChanges = () => {
    mediator.base.dispatch({
      type: "PICKS_ALERTS:USER_ACTIONS",
      payload: {
        action: "Cancel"
      }
    });
    this.props.onClose();
    this.setState({
      selectedTab: "talent"
    });
  };

  saveAlerts = () => {
    mediator.base.dispatch({
      type: "PICKS_ALERTS:USER_ACTIONS",
      payload: {
        action: "Confirm"
      }
    });
    this.props.onClose();

    const tracksToUpdate = getAlertsToUpdateUFC(
      this.state.trackAlerts,
      this.userTrackAlerts
    );
    const talentsToUpdate = getAlertsToUpdateUFC(
      this.state.talentAlerts,
      this.userTalentAlerts
    );

    updateAlertsOnUFC(this.props.accountNumber, tracksToUpdate, "track");
    updateAlertsOnUFC(this.props.accountNumber, talentsToUpdate, "talent");
  };

  handleTabClick = (tabName: SelectedTab) => {
    if (this.state.selectedTab !== tabName) {
      this.setState({
        selectedTab: tabName
      });
    }
    mediator.base.dispatch({
      type: "PICKS_ALERTS:USER_ACTIONS",
      payload: {
        action: `${tabName === "track" ? "Track" : "Talent"} Alerts`
      }
    });
  };

  fetchTalents = () =>
    tpmService.getAllTalents({ isActive: true }).then((result) => {
      const activeTalents = get(result, "data").filter(
        (talent) => talent.isActive
      );
      const talentAlerts = activeTalents.map((talent) => ({
        pictureUrl: `//${this.tvg.config().domain.desktop}/${
          talent.pictureUrl
        }`,
        id: talent.talentId,
        talentName: talent.name,
        isActive: false,
        onClick: (isActive: boolean) =>
          this.updateAlerts("talent", talent.talentId, talent.name, isActive)
      }));
      this.setState({
        talentAlerts
      });
      return talentAlerts;
    });

  render() {
    const baseProps = {
      title: this.props.title,
      titleType: "default",
      isOpen: this.props.isOpen,
      onClose: () => this.props.onClose(true),
      isFullHeight: true,
      qaLabel: "modal-picks-alerts"
    };

    const props =
      this.tvg.device === "mobile"
        ? { ...baseProps, animation: "bottom", hasOverlay: true }
        : {
            ...baseProps,
            animation: "right",
            hasOverlay: true,
            isFullWidth: false,
            hasRoundedCorners: false,
            offsetTop: 0
          };

    return (
      <ModalV2 {...props}>
        {() => (
          <PickAlertsTemplate
            isLoading={this.state.isLoading || this.props.isLoading}
            enablePicksTracksTabAlerts={this.props.enablePicksTracksTabAlerts}
            talentsList={this.state.talentAlerts}
            tracksList={this.state.trackAlerts}
            selectedTab={this.state.selectedTab}
            onConfirm={this.saveAlerts}
            onCancel={this.cancelChanges}
            handleTabClick={this.handleTabClick}
            updateAlerts={this.updateAlerts}
            talentAlertsInfoMessage={this.props.talentAlertsInfoMessage}
            trackAlertsInfoMessage={this.props.trackAlertsInfoMessage}
          />
        )}
      </ModalV2>
    );
  }
}

export default graphql(
  AllTracksQuery,
  ApolloOptions
)(PicksAlertsModalComponent);
