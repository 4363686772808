import { ColorTokens } from "../../../src/theming/themes/tokens/types";
import { ButtonColors, ButtonStatus } from "./types";

export const buttonColors = (colorTokens: ColorTokens): ButtonColors => ({
  [ButtonStatus.DEFAULT]: {
    line: colorTokens.component.button.tertiary.content.base,
    background: colorTokens.background.surface
  },
  [ButtonStatus.HOVER]: {
    line: colorTokens.component.button.tertiary.content.hover,
    background: colorTokens.component.button.tertiary.background.hover
  },
  [ButtonStatus.ACTIVE]: {
    line: colorTokens.component.button.tertiary.content.active,
    background: colorTokens.component.button.tertiary.background.active
  },
  [ButtonStatus.DISABLED]: {
    line: colorTokens.component.button.tertiary.content.disabled,
    background: colorTokens.component.button.tertiary.background.disabled
  }
});
