// @flow
import styled, { css } from "styled-components";
import buildColor from "../ColorPalette";
import { fontNormal, fontBold } from "../Typography";

export const SeoContainer = styled.div`
  color: ${buildColor("grey", "800")};
  background-color: ${buildColor("white", "100")};
  border-radius: 4px;
  font-family: ${fontNormal};
  font-weight: normal;
  padding: 15px 12px 20px;
  box-shadow: inset 0 0 0 1px ${buildColor("blue", "100")};
  ${(props) =>
    props.device === "desktop" &&
    css`
      padding: 17px 12px 12px;
      margin-bottom: 12px;
    `}

  ${({ addBottomMargin }) =>
    addBottomMargin &&
    css`
      margin-bottom: 48px;
    `}

  p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 18px;
    padding: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  a {
    vertical-align: baseline;
    color: ${buildColor("grey", "800")};
    cursor: pointer;
  }

  a:hover {
    color: ${buildColor("grey", "900")};
  }

  ul > li {
    list-style-type: disc;
  }

  ul {
    padding-left: 20px;
  }
`;

export const SeoFooterTitle = styled.h2`
  color: ${buildColor("grey", "900")};
  font-family: ${fontBold};
  font-size: 18px;
  margin-bottom: 18px;
  ${(props) =>
    props.device !== "desktop" &&
    css`
      font-size: 16px;
      margin-bottom: 14px;
    `}
`;
