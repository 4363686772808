import styled, { css } from "styled-components";
import { tvgColorPalette as colors } from "@tvg/design-system/src/theming/themes/common/tvgColorPalette";
import { fonts } from "@tvg/design-system/src/theming/themes/common/fonts";

export const InfoContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Description = styled.span`
  text-transform: capitalize;
  display: block;
  font-weight: bold;
  text-align: left;
  padding-bottom: 2px;
  font-size: 12px;
  ${({ theme }) => `color: ${theme.colorTokens.content.default}`};
`;

export const OptionItem = styled.span`
  position: relative;
  font-size: 12px;
  color: ${({ theme }) => theme.colorTokens.content.subtle};

  &:not(:last-child) {
    margin-right: 4px;
    padding-right: 5px;
  }
`;

export const InfoItems = styled.ul`
  white-space: normal;
  font-size: 12px;
  line-height: 18px;
  font-family: ${fonts.regular};
  color: ${({ theme }) => theme.colorTokens.content.subtle};
`;

export const InfoItem = styled.li<{
  isHighlighted: boolean;
  hasChanges: boolean;
}>`
  display: inline;
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      font-family: ${fonts.medium};
      font-weight: 500;
    `};
  ${({ hasChanges }) =>
    hasChanges &&
    css`
      color: ${colors.orange[700]};
    `};
`;

export const Separator = styled.span`
  font-weight: normal;
  line-height: 17px;
  vertical-align: initial;
  font-size: 12px;
  font-family: ${fonts.regular};
  ${({ theme }) => `color: ${theme.colorTokens.background.neutral}`};
`;

export const FavoriteTag = styled.span`
  margin-left: 2px;
  padding: 1px 6px;
  border-radius: 12px;
  font-style: normal;
  line-height: 14px;
  text-align: center;
  vertical-align: text-top;
  font-family: ${fonts.condensedRegular};
  font-weight: 500;
  font-size: 12px;
  ${({ theme }) => `
    color: ${theme.colorTokens.content.alert};
    background: ${theme.colorTokens.component.tag.offer_subtle.subtle};
  `}
`;

export const IconContainer = styled.div`
  display: inline-flex;
  margin-right: 4px;
  vertical-align: inherit;
`;
