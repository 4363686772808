import styled from "styled-components";
import { Theme } from "../../../src/theming/types";

export const CarouselContainer = styled.div<{ theme: Theme }>`
  width: 100%;
  padding: ${({ theme }: { theme: Theme }) => theme.space["space-5"]};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ListItems = styled.div`
  display: flex;
  overflow-x: hidden;
  gap: ${({ theme }: { theme: Theme }) => theme.space["space-1"]};
  scroll-snap-type: x mandatory;
  padding: ${({ theme }: { theme: Theme }) => theme.space["space-5"]} 0;
  width: 100%;
  & > div {
    scroll-snap-align: start;
  }
`;
