import React, { FC, useEffect, useState } from "react";
import { Icon } from "../../../src/components/icon";
import { ButtonsContainer, NavButton } from "./styled-components";
import { ButtonStatus, NavigationButtonProps } from "./types";
import { useColorTokens } from "../../hooks";
import { buttonColors } from "./buttonColors";

const NavigationButton: FC<NavigationButtonProps> = ({
  qaLabel = "navigationBtn",
  pageCount,
  onLeftClick,
  onRightClick
}) => {
  const colors = buttonColors(useColorTokens());
  const [currentPage, setCurrentPage] = useState(1);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);
  const [leftStatus, setLeftStatus] = useState(ButtonStatus.DISABLED);
  const [rightStatus, setRightStatus] = useState(ButtonStatus.DEFAULT);

  useEffect(() => {
    // Left Button
    setLeftStatus(
      currentPage === 1 ? ButtonStatus.DISABLED : ButtonStatus.DEFAULT
    );
    setIsLeftDisabled(currentPage === 1);

    // Right Button
    setRightStatus(
      currentPage === pageCount ? ButtonStatus.DISABLED : ButtonStatus.DEFAULT
    );
    setIsRightDisabled(currentPage === pageCount);
  }, [currentPage, pageCount]);

  const handleLeftClick = () => {
    setLeftStatus(ButtonStatus.ACTIVE);
    onLeftClick();

    setTimeout(() => {
      setLeftStatus(ButtonStatus.HOVER);
      setCurrentPage(currentPage - 1);
    }, 100);
  };

  const handleRightClick = () => {
    setRightStatus(ButtonStatus.ACTIVE);
    onRightClick();

    setTimeout(() => {
      setRightStatus(ButtonStatus.HOVER);
      setCurrentPage(currentPage + 1);
    }, 100);
  };

  return (
    <ButtonsContainer data-qa-label={qaLabel}>
      <NavButton
        data-qa-label={`${qaLabel}-left-button`}
        isDisabled={isLeftDisabled}
        onMouseEnter={() =>
          !isLeftDisabled && setLeftStatus(ButtonStatus.HOVER)
        }
        onMouseLeave={() =>
          !isLeftDisabled && setLeftStatus(ButtonStatus.DEFAULT)
        }
        onClick={handleLeftClick}
        buttonColors={colors[`${leftStatus}`]}
      >
        <Icon name="arrowLeft" size="s" />
      </NavButton>
      <NavButton
        data-qa-label={`${qaLabel}-right-button`}
        isDisabled={isRightDisabled}
        onMouseEnter={() =>
          !isRightDisabled && setRightStatus(ButtonStatus.HOVER)
        }
        onMouseLeave={() =>
          !isRightDisabled && setRightStatus(ButtonStatus.DEFAULT)
        }
        onClick={handleRightClick}
        buttonColors={colors[`${rightStatus}`]}
      >
        <Icon name="arrowRight" size="s" />
      </NavButton>
    </ButtonsContainer>
  );
};

export default NavigationButton;
