import React, { MouseEvent, useMemo } from "react";
import { get } from "lodash";

import type { PreviousWinnerWRCResponse } from "@tvg/ts-types/PreviousWinners";
import { sendRaceCellClickedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import { Module } from "@tvg/amplitude/modules/raceCell/types";
import {
  getRacePromos,
  isUserOptedInPromo
} from "@urp/lib-racetracks/src/utils/races";
import { useNavigate } from "@tvg/custom-hooks";
import tvgConf from "@tvg/conf";
import { Race } from "@tvg/ts-types/Race";
import { getOptedInPromos } from "@urp/store-selectors";
import { useSelector } from "react-redux";
import { isTvg5 } from "@tvg/utils/generalUtils";
import type { PreviousWinnersComponentProps } from "./types";
import { navigateToBetSlip } from "./utils";
import usePreviousWinnersState from "./hooks/usePreviousWinnersState";
import usePreviousWinnersWithRaces from "./hooks/usePreviousWinnersWithRaces";
import usePreviousWinnersRacesData from "./hooks/usePreviousWinnersRacesData";
import PreviousWinners from "./components/PreviousWinnersContainer";
import LoadingMask from "./components/LoadingMask";

export default function PreviousWinnersComponent(
  props: PreviousWinnersComponentProps
) {
  const { device, title } = props;
  const history = useNavigate();
  const { isLogged, catchPhrase, modalTitle, winners, isLoading, matchRaces } =
    usePreviousWinnersState();
  const { races } = usePreviousWinnersRacesData({
    matchRaces,
    wagerProfile: get(props, "wagerProfile")
  });
  const { previousWinners, racesResolved, hasWinners } =
    usePreviousWinnersWithRaces(races, winners);

  const optedInPromos = useSelector(getOptedInPromos);

  const onPreviousWinnerSelection = (
    winner: PreviousWinnerWRCResponse,
    url: string,
    event?: MouseEvent
  ) => {
    event?.preventDefault();

    if (device === "desktop" && !isTvg5()) {
      document.body.classList.remove("modal");
    }

    const runner = winner.currentRace.bettingInterestNumber;
    const newUrl = tvgConf().buildUrl({
      path: `${url}&selectedRunner=${runner}`
    });

    const availableRacePromos = getRacePromos(
      winner.currentRace as unknown as Race
    );
    const isOptedIn = isUserOptedInPromo(optedInPromos, availableRacePromos);

    sendRaceCellClickedAnalyticEvt({
      module: Module.WINNERS,
      trackName: winner.currentRace.trackName,
      raceNumber: winner.currentRace.raceNumber.toString(),
      linkUrl: newUrl,
      raceHasPromos: !!availableRacePromos.length,
      trackHasPromos: false,
      promoOptedIn: isOptedIn ? "true" : "false",
      isFDTV: !!(
        winner.currentRace?.video?.onTvg || winner.currentRace?.video?.onTvg2
      ),
      trackCountry: winner.currentRace.location?.country ?? ""
    });

    if (device === "desktop" && !isTvg5()) {
      navigateToBetSlip(url, winner, history);
    } else {
      history.push(url);
    }
  };

  return useMemo(() => {
    if (!isLogged || (!isLoading && !hasWinners)) return null;

    return isLoading || !racesResolved ? (
      <LoadingMask isDesktop={device === "desktop"} />
    ) : (
      <PreviousWinners
        winners={previousWinners}
        isLoading={isLoading}
        isDesktop={device === "desktop"}
        title={title}
        catchPhrase={catchPhrase}
        modalTitle={modalTitle}
        onSelection={onPreviousWinnerSelection}
      />
    );
  }, [
    JSON.stringify(winners),
    isLogged,
    isLoading,
    matchRaces,
    JSON.stringify(races),
    racesResolved
  ]);
}
