import { get } from "lodash";

import { Race } from "@tvg/ts-types/Race";
import { HomepageEventBannerText } from "@urp/derby-banner/src/types";
import { HomepageHeaderBanner } from "../types";

type UTCDate = [number, number, number];
const MONTH_INDEX = 1;

export const getTrackAbbr = (store: unknown): string | undefined =>
  get(store, "home.eventInfo.trackAbbr");

export const getRaceNumber = (store: unknown): number | undefined =>
  get(store, "home.eventInfo.raceNumber");

export const getEventDate = (store: unknown): Date | null =>
  new Date(get(store, "home.eventInfo.eventDate", null));

export const getTodayDate = (store: unknown): Date => {
  const currentLocalDate = new Date();
  const raceDate: UTCDate = get(store, "mtpStatus.raceDate", "")
    .split("-")
    .map((date: string, i: number) => {
      const parsedDate = +date;
      return MONTH_INDEX === i ? parsedDate - 1 : parsedDate;
    });
  const todayDate: UTCDate =
    raceDate.length === 3
      ? raceDate
      : [
          currentLocalDate.getUTCFullYear(),
          currentLocalDate.getUTCMonth(),
          currentLocalDate.getUTCDate()
        ];

  return new Date(Date.UTC(...todayDate));
};

export const geDerbyBanner = (store: unknown): HomepageHeaderBanner =>
  get(store, "home.derbyBanner", undefined);

export const getDerbyRace = (store: unknown): Race | undefined =>
  get(store, "home.derbyRace");

export const getEventName = (store: unknown): HomepageEventBannerText =>
  get(store, "home.eventInfo.eventName", {
    label: "",
    size: 0
  });
