// @flow
import React, { PureComponent } from "react";
import type { Device } from "@tvg/types/Device";

import { SeoContainer, SeoFooterTitle } from "./styled-components";

type Props = {
  message: string,
  title: string,
  device: Device,
  addBottomMargin?: boolean
};

export default class SeoFooter extends PureComponent<Props> {
  static defaultProps = {
    message: "",
    title: "Horse Racing Betting",
    device: "mobile",
    addBottomMargin: false
  };

  render() {
    return (
      <SeoContainer
        device={this.props.device}
        addBottomMargin={this.props.addBottomMargin || false}
        data-qa-label="seoContainer"
      >
        <SeoFooterTitle data-qa-label="seoFooterTitle">
          {this.props.title}
        </SeoFooterTitle>
        <div
          data-qa-label="seoMessage"
          dangerouslySetInnerHTML={{ __html: this.props.message }}
        />
      </SeoContainer>
    );
  }
}
