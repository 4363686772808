import styled from "styled-components";
import { ButtonNavProps } from "./types";
import { Theme } from "../../../src/theming/types";

export const ButtonsContainer = styled.div`
  display: flex;
  border: 2px solid
    ${({ theme }: { theme: Theme }) =>
      theme.colorTokens.component.button.tertiary.border.base};
  border-radius: 4px;
`;

export const NavButton = styled.div<{
  buttonColors: ButtonNavProps;
  isDisabled: boolean;
}>`
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  padding: ${({ theme }: { theme: Theme }) => theme.space["space-3"]};
  background-color: ${({ buttonColors }) => buttonColors.background};

  & svg path {
    stroke: ${({ buttonColors }) => buttonColors.line};
  }
`;
