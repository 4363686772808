import { sortBy, get } from "lodash";
import type { UserOptedInPromos } from "@tvg/ts-types/User";
import type {
  Race,
  RaceStatusEnum,
  RaceTypeCodeEnum,
  Promo
} from "@tvg/ts-types/Race";
import type { SeoTrack, Track } from "@tvg/ts-types/Track";
import { RegionFiltersEnum } from "../types";

export const getTrackName = (trackName: string, delimiter = " - ") => {
  const indexOfDelimiter = trackName.indexOf(delimiter);
  return indexOfDelimiter > 0
    ? trackName.slice(indexOfDelimiter + delimiter.length)
    : trackName;
};

export const findTrack = (
  tracks: Array<Track>,
  trackCode: string | undefined
) => tracks.find((track) => track.code === trackCode);

export const filterTracksByRegion = (
  tracks: Array<SeoTrack | Track>,
  regionsFilter: Array<string>
): Array<SeoTrack | Track> =>
  regionsFilter.length > 0
    ? tracks.filter((track) =>
        isRegionIncluded(
          regionsFilter,
          track.location?.country || RegionFiltersEnum.International
        )
      )
    : tracks;

export const filterRacesByTrackCode = (
  races: Array<Race>,
  trackCodes: Array<string>
) =>
  trackCodes.length > 0
    ? races.filter((race) => trackCodes.includes(race.track.code))
    : races;

export const filterRacesByRaceType = (
  races: Array<Race>,
  raceTypesFilter: Array<RaceTypeCodeEnum>
) =>
  raceTypesFilter.length > 0
    ? races.filter((race) => raceTypesFilter.includes(race.type.code))
    : races;

export const filterRacesByStatus = (
  races: Array<Race>,
  raceStatusFilter: Array<RaceStatusEnum>
) =>
  raceStatusFilter.length > 0
    ? races.filter((race) => raceStatusFilter.includes(race.status.code))
    : races;

export const isRegionIncluded = (regions: Array<string>, region: string) =>
  regions.includes(region) ||
  (regions.includes(RegionFiltersEnum.International) &&
    region !== RegionFiltersEnum.US);

export const isTrackOpen = (openTracks: string[], trackCode: string): boolean =>
  openTracks.includes(trackCode);

export const getOpenTracksRaces = (
  openTracks: Array<string>,
  tracks: Array<Track>,
  races: Array<Race>
): Array<Track> =>
  tracks.map((track) =>
    openTracks.includes(track.code)
      ? {
          ...track,
          races: races.filter((race) => race.track.code === track.code)
        }
      : track
  );

export const filterTracksWithRaces = (
  tracks: Array<Track>,
  races: Array<Race>
) =>
  tracks.filter((track) =>
    races.some((race) => race.track.code === track.code)
  );

export const getUpcomingRaces = (
  allUpcomingRaces: Array<Race>,
  maxResults: number
) => sortBy(allUpcomingRaces, ["mtp", "track.name"]).slice(0, maxResults);

export const getRacesWithTrackLocation = (
  races: Array<Race>,
  tracks: Array<Track>
) =>
  races.map((race) => {
    const track = findTrack(tracks, race.track.code);

    return {
      ...race,
      track: {
        ...race.track,
        hasAboveTheLinePromo: !!track?.hasAboveTheLinePromo,
        name: race.track.name,
        location: {
          country: track?.location?.country || "",
          state: track?.location?.state || ""
        }
      }
    };
  });

export const mergeValues = (list: Array<string>, newValue: string) =>
  list.includes(newValue)
    ? list.filter((filterRaceType) => filterRaceType !== newValue)
    : [...list, newValue];

export const getRacePromos = (race: Race) =>
  race.promos?.filter((promo) => promo.isPromoTagShown) ?? [];

export const hasPromoAvailable = (race: Race) =>
  race.promos?.filter((promo) => promo.isAboveTheLine) ?? [];

export const isUserOptedInPromo = (
  optedInPromos: UserOptedInPromos,
  promos: Promo[]
) => promos.some((promo) => get(optedInPromos, promo.rootParentPromoID, false));
