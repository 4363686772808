import styled, { keyframes } from "styled-components";
import { Props, StyledProps } from "./types";

const animation = keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  20% {
    height: auto;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div<StyledProps>`
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colorTokens.border.subtle}`};
  height: ${({ contentHeight, isOpen, minHeight }) =>
    isOpen ? `${contentHeight}px` : `${minHeight}px`};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  max-width: 100vw;
`;

export const Summary = styled.div`
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-2"]} ${theme.space["space-4"]} ${theme.space["space-5"]}`};
  flex: 1;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  min-height: 24px;
  cursor: pointer;
`;

export const Content = styled.div<Pick<Props, "isOpen">>`
  display: flex;
  padding: ${({ theme }) => theme.space["space-4"]} 0;
  height: ${({ isOpen }) => (isOpen ? "auto" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  animation: ${animation} 0.5s ease-in 0.2s forwards;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  & > :last-child {
    margin-left: ${({ theme }) => theme.space["space-4"]};
  }
`;
