// @flow
import { noop } from "lodash";
import FavoriteTracksTemplate from "@tvg/atomic-ui/_templates/FavoriteTracks";
import type { TrackComponentBehavior } from "./types";
import RDAQuery from "../graphql/queries/TracksFavoritesRDAQuery.graphql";
import GraphOptions from "../graphql/tracks-favorites-options.graph";

type QueryTypes = {
  isAccountCompliant: boolean,
  shouldUpdate: boolean
};

export const getDerivedStateFromProps = () => ({
  firstRender: false,
  innerUpdate: false
});

export const skipQuery = () => true;

export const skipRDAQuery = ({
  isAccountCompliant,
  shouldUpdate,
  accountId
}: QueryTypes & { accountId: string }) =>
  !isAccountCompliant || !shouldUpdate || !accountId;

const tracksFavorite: TrackComponentBehavior = {
  template: FavoriteTracksTemplate,
  query: null,
  rdaQuery: RDAQuery,
  graphOptions: GraphOptions,
  skipQuery,
  skipRDAQuery,
  usesRDA: true,
  onlyUsesRDA: true,
  onlyFetchResultedTracks: false,
  renderTracksLetters: false,
  shouldComponentUpdate: noop,
  getDerivedStateFromProps,
  numberOfFavorites: 0,
  hasActiveFilters: false,
  onClearFilters: noop,
  tracks: []
};

export default tracksFavorite;
