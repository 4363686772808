import React, { useEffect, useMemo, useRef, useState } from "react";
import { Saddle } from "@tvg/design-system";
import { noop } from "lodash";
import {
  Container,
  SilkContainer,
  SaddleContainer,
  SilkImage,
  SilkBorderContainer
} from "./styled-components";

import Silk from "./silk";
import SilkBorder from "./silkBorder";
import { Props } from "./types";

export const SaddleClothSilk = ({
  number,
  isScratched = false,
  numberColor,
  saddleColor,
  silkImage,
  isAmericanRace = false,
  isDesktop = true,
  allRunnersHasSilksError,
  handleError = noop,
  showSilks = false,
  useUpdatedSilk = false
}: Props) => {
  const [hasSilkError, setSilkError] = useState(false);
  const [silkIsLoadad, setSilkIsLoaded] = useState(false);
  const [isSilkVisible, setIsSilkVisible] = useState(showSilks);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setSilkError(false);
  }, [silkImage]);

  useEffect(() => {
    if (!!ref.current && useUpdatedSilk) {
      setIsSilkVisible(showSilks && ref.current.clientHeight >= 45);
    } else if (isDesktop) {
      setIsSilkVisible(showSilks);
    }
  }, [ref.current, showSilks, isDesktop]);

  const handleDefaultSilk = () => (isSilkVisible ? <Silk /> : <></>);

  return useMemo(
    () => (
      <Container
        hasSilk={isSilkVisible && !allRunnersHasSilksError}
        ref={ref}
        useUpdatedSilk={useUpdatedSilk}
      >
        <SaddleContainer useUpdatedSilk={useUpdatedSilk}>
          <Saddle
            isScratched={isScratched}
            number={number || 0}
            numberColor={numberColor}
            saddleColor={saddleColor}
            size="m"
          />
        </SaddleContainer>
        {isSilkVisible && (
          <SilkContainer
            isScratched={isScratched}
            useUpdatedSilk={useUpdatedSilk}
          >
            {silkImage && showSilks ? (
              <>
                {silkIsLoadad && isAmericanRace && (
                  <SilkBorderContainer useUpdatedSilk={useUpdatedSilk}>
                    <SilkBorder />
                  </SilkBorderContainer>
                )}
                <SilkImage
                  src={silkImage}
                  alt={`Silk image for runner number ${number}`}
                  onError={() => {
                    handleError(Number(number));
                    setSilkError(true);
                  }}
                  onLoad={() => setSilkIsLoaded(true)}
                  useUpdatedSilk={useUpdatedSilk}
                />
              </>
            ) : (
              handleDefaultSilk()
            )}
          </SilkContainer>
        )}
      </Container>
    ),
    [
      silkImage,
      number,
      saddleColor,
      hasSilkError,
      isScratched,
      silkIsLoadad,
      isSilkVisible,
      allRunnersHasSilksError,
      handleError,
      useUpdatedSilk,
      showSilks
    ]
  );
};
